import { setIsPrivate, setIsPublished } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import Button from "../ui/Button/Button";

const VisibilityModal = ({ isOpen, closeModal }) => {
  const { propertyId, isPrivate } = useSelector((state) => state.myProperties);
  const dispatch = useDispatch();

  const handlePublish = async () => {
    dispatch(setIsPublished(true));
    closeModal();
    try {
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: { isPrivate },
      });
    } catch (e) {
      console.log(e);
      toast.error(i18n("Failed to set visibility status. Please try again."), {
        duration: 5000,
      });
    }

    toast.success(
      i18n(
        `Visibility status set to: ${isPrivate ? "Private Listing" : "Public Listing"}`,
      ),
      { duration: 5000 },
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Visibility status settings")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <div className="flex w-full flex-col gap-3">
            <p className="text-base font-normal text-[#717171]">
              {i18n("Select your property listing visibility.")}
            </p>
            <div className="flex justify-between gap-4">
              <div
                onClick={() => dispatch(setIsPrivate(false))}
                className={`flex w-1/2 gap-3 rounded-lg border p-4 ${
                  isPrivate === false
                    ? "border-black bg-[#2222221A]"
                    : "border-[#BABABA]"
                }`}
              >
                <div
                  className={`flex h-6 w-6 min-w-6 items-center justify-center rounded-full border ${
                    isPrivate === false ? "border-black" : "border-[#BABABA]"
                  }`}
                >
                  {isPrivate === false && (
                    <div className="h-3 w-3 rounded-full bg-black"></div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p
                    className={`text-base font-semibold ${
                      isPrivate === false ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Public Listing")}
                  </p>
                  <p
                    className={`text-sm ${
                      isPrivate === false ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n(
                      "Can be found by searching and will be visible to others.",
                    )}
                  </p>
                </div>
              </div>
              <div
                onClick={() => dispatch(setIsPrivate(true))}
                className={`flex w-1/2 gap-3 rounded-lg border p-4 ${
                  isPrivate ? "border-black bg-[#2222221A]" : "border-[#BABABA]"
                }`}
              >
                <div
                  className={`flex h-6 w-6 min-w-6 items-center justify-center rounded-full border ${
                    isPrivate ? "border-black" : "border-[#BABABA]"
                  }`}
                >
                  {isPrivate && (
                    <div className="h-3 w-3 rounded-full bg-black"></div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p
                    className={`text-base font-semibold ${
                      isPrivate ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Private Listing")}
                  </p>
                  <p
                    className={`text-sm ${
                      isPrivate ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Hidden from search and only accessible if shared.")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={handlePublish} disabled={isPrivate === null}>
              <div>{i18n("Publish")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default VisibilityModal;
