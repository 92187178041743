import {
  setCanDrawGeographicPolygons,
  setAreaSelectionMode,
  setAvailableAreas,
  setSelectedAreas,
} from "actions/mapActions";
import {
  setFilteredProperties,
  setProperties,
} from "actions/propertiesActions";
import {
  getMapObjectByGeoboundarySkeleton,
  getMapObjectById,
} from "db/mapStore";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import React from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import {
  clearAllDrawnGeographicPolygons,
  clearAllSelectedMunicipalityPolygons,
  onCreatePolygon,
  setSelectedDrawnDistricts,
} from "utils/map";
import {
  getAllMunicipalitiesBetweenAandB,
  gMapPathToPolygon,
} from "utils/polygon";
import {
  setBuildingType,
  setPropertyFilters,
  setSaleType,
  setSort,
} from "../../actions/filterActions";
import { getSearchIndexes } from "../../actions/geoActions";
import {
  setGlobalLoading,
  setGlobalLoadingMessage,
} from "../../actions/miscActions";
import { generateFiltersFromNaturalLanguageRequest } from "../../api/ai";
import { fetchPropertyByUrlRequest } from "../../api/properties";
import closeIcon from "../../assets/core/close.svg";
import downChevronIcon from "../../assets/core/down_chevron.svg";
import searchIcon from "../../assets/search/search_icon.svg";
import {
  aiFiltersToBuildingType,
  aiFiltersToFrontendFilters,
} from "../../lib/filter/filters";
import { aiSortToFrontendSort } from "../../lib/filter/sort";
import store from "../../store";
import {
  convertRefToId,
  debounce,
  getBestMatchLocation,
  getDistrictsForMunicipality,
  normalizeString,
} from "../../utils/helpers";
import "./search.scss";
import { setDrawnPolygons } from "actions/polygonActions";
import AiSearchHintDialog from "./AiSearchHintDialog";

const DROPDOWN_TYPE_AI_ASSISTANT = "AI Assistant";
const DROPDOWN_TYPE_LIST_OF_AREAS = "List of Areas";
const DROPDOWN_TYPE_URL = "URL";
const DROPDOWN_TYPE_REF = "Reference";

const LIST_OF_DROPDOWN_ITEMS = {
  [DROPDOWN_TYPE_AI_ASSISTANT]: () => i18n("AI Assistant"),
  [DROPDOWN_TYPE_LIST_OF_AREAS]: () => i18n("List of Areas"),
  [DROPDOWN_TYPE_URL]: () => "URL",
  [DROPDOWN_TYPE_REF]: () => i18n("Reference"),
};

const SEARCH_TYPE_PLACEHOLDER_MAPPING = {
  [DROPDOWN_TYPE_LIST_OF_AREAS]: () =>
    i18n("Search for a Region, Municipality or Province"),
  [DROPDOWN_TYPE_AI_ASSISTANT]: () =>
    i18n("I would like a 3 bedroom house in Guadalmina, Marbella"),
  [DROPDOWN_TYPE_URL]: () => "https://www.idealista.com/inmueble/103237516/",
  [DROPDOWN_TYPE_REF]: () => "i103259577001",
};

const WAITING_MESSAGING_TIME_AI_LOADER = 1500;

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      searchValue: "",
      searchResults: [],
      searchFocusIndex: -1,
      searchType: DROPDOWN_TYPE_AI_ASSISTANT,
      aiSearchHintOpen: false,
      aiFilters: [],
    };

    this.searchInput = React.createRef();
    this.searchIndexes = getSearchIndexes();
  }

  componentDidMount() {
    // any clicks outside the dropdown should close the dropdown
    document.addEventListener("click", (event) => {
      if (
        !event.target.closest(".search-bar_dropdown") &&
        !event.target.closest(".search-bar_dropdown-toggler")
      ) {
        this.closeDropdown();
      }
    });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });

    sendAnalyticsEvent("Search Bar Click", {
      type: this.state.dropdownOpen ? "open dropdown" : "close dropdown",
    });
  }

  closeDropdown() {
    this.setState({
      dropdownOpen: false,
    });
  }

  onSaleTypeChange(saleType) {
    if (saleType === this.props.filters.saleType) {
      return;
    }

    this.props.dispatch(setProperties([]));
    this.props.dispatch(setFilteredProperties([]));
    this.props.dispatch(setPropertyFilters([]));
    this.props.dispatch(setSaleType(saleType));

    // don't start fetching properties if we are in district selection mode
    if (this.props.mapReducer.areaSelectionMode) {
      return;
    }

    // refetch all properties since the sale type has changed
    // and we point to new data tables
    for (let polygon of window.drawnPolygons) {
      const paths = polygon.getPath().getArray();
      const polygonCoords = gMapPathToPolygon(paths);
      onCreatePolygon(polygonCoords, polygon.irealtyId);
    }
  }

  // creates the polygon using the provided polygon path

  // google places api for general searches
  onPlaceSearch(searchText) {
    // Create a PlacesService instance
    const placesService = new window.google.maps.places.PlacesService(
      this.props.map,
    );

    // Perform the text search
    placesService.textSearch(
      {
        query: searchText,
        region: "es",
      },
      (results, status) => {
        this.setState({
          searchResults: [],
          searchFocusIndex: -1,
        });

        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results &&
          results[0]
        ) {
          // Get the bounds of the region.
          const bounds = results[0].geometry.viewport;

          // Calculate the zoom level.
          this.props.map.fitBounds(bounds, null, {
            maxZoom: 18,
          });
        } else {
          console.error("Text search failed:", status);
        }
      },
    );
  }

  /**
   * handler for when a location is selected from the search dropdown
   * we fly top to the locations square bounds then make a request to
   * draw the polygon of the location's administrative bounds if its a district
   */
  async onLocationSelect(location) {
    sendAnalyticsEvent("Area Search", {
      type: location.type,
      name: location.name,
    });

    this.searchInput.current.blur();
    store.dispatch(setAreaSelectionMode(null));

    this.setState({
      dropdownOpen: false,
      searchResults: [],
      searchValue: location.name,
    });

    // Define the bounds of the desired bounding box
    const southwest = new window.google.maps.LatLng(
      parseFloat(location.max_lat),
      parseFloat(location.min_lng),
    ); // Southwest corner
    const northeast = new window.google.maps.LatLng(
      parseFloat(location.min_lat),
      parseFloat(location.max_lng),
    ); // Northeast corner
    const bounds = new window.google.maps.LatLngBounds(southwest, northeast);

    // Use the fitBounds method to adjust the map's viewport
    this.props.map.fitBounds(bounds);
    this.props.onShiftMapForPropertyPanel();

    let canDrawPolygon =
      location.type === "district" ||
      location.type === "locality" ||
      location.type === "municipality" ||
      location.type === "zone";

    // if its a selectable polygon, either fetch properties for it
    // or open the district selection panel if it has subdivisions
    if (canDrawPolygon) {
      // do not fetch properties if we have districts for the municipality
      // as we want to open the district selection panel for it
      if (location.type === "municipality") {
        const districtsOfMunicipality = await getDistrictsForMunicipality(
          location.mun_code,
        );

        if (districtsOfMunicipality.length > 0) {
          let municipality = await getMapObjectById(
            `${location.country}-municipalities`,
            location.id,
          );

          // timeout to allow the map to settle before selecting the municipality
          // otherwise the polygon will not be available to the state
          setTimeout(() => {
            this.props.handleMunicipalitySelection(
              [municipality].concat(districtsOfMunicipality),
              location.mun_code,
              location.id,
            );
          }, 250);
          return;
        }
      }

      try {
        store.dispatch(setCanDrawGeographicPolygons(false));
        clearAllSelectedMunicipalityPolygons();
        let response = await getMapObjectByGeoboundarySkeleton(location);
        this.props.drawArea(response, {
          fetchProperties: true,
        });
        this.props.zoomToDrawnPolygons([response]);
      } catch (e) {
        console.log(e);
      }
    }

    store.dispatch(setCanDrawGeographicPolygons(true));
  }

  // executes ai search and draws polygon and dispatches filters instructed by the ai api
  executeAiSearch(searchValue) {
    if (searchValue.length === 0) return;

    if (searchValue.length > 300) {
      toast.error(i18n("Search query too long"));
      return;
    }

    // lets firstly check if the search value is just a location name
    // if so we can just fly to the location and return
    const foundLocation = getBestMatchLocation(
      this.searchIndexes.indexes.filter((i) => i.type !== "province"),
      searchValue,
    );
    console.log(foundLocation.score);
    if (foundLocation.score >= 0.9) {
      this.onLocationSelect(foundLocation);
      return;
    }

    // capture ai searches as analytics events
    sendAnalyticsEvent("AI Search", {
      query: searchValue,
    });

    // clear current state
    this.setState({ aiFilters: [] });
    store.dispatch(setProperties([]));
    store.dispatch(setFilteredProperties([]));
    store.dispatch(setDrawnPolygons([]));
    for (let polygon of window.drawnPolygons) {
      polygon.setMap(null);
    }
    store.dispatch(setAreaSelectionMode(null));

    // global app loading state
    store.dispatch(setGlobalLoading(true));
    store.dispatch(
      setGlobalLoadingMessage(
        i18n("Generating filters for") + ' "' + searchValue + '"...',
      ),
    );

    generateFiltersFromNaturalLanguageRequest(searchValue)
      .then(async (result) => {
        // dispatch filter updates to state
        let frontendFilters = [];
        let zoomToBounds = true;

        if (result.filters) {
          // if the filters returned have a sale type, update the sale type filter
          if (result.filters.saleType) {
            this.onSaleTypeChange(result.filters.saleType);
          }

          this.setState({ aiFilters: result.filters });
          let buildingType = aiFiltersToBuildingType(result.filters);
          frontendFilters = aiFiltersToFrontendFilters(
            result.filters,
            buildingType.value,
          );
          let frontendSort = aiSortToFrontendSort(result.filters.sort);
          store.dispatch(setBuildingType(buildingType));
          if (frontendSort) store.dispatch(setSort(frontendSort));
          store.dispatch(setPropertyFilters(frontendFilters));
        }

        let areasDrawn = [];

        // check for a between_locations response, as the workflow is different
        // we open up the area selection panel
        if (result.between_locations) {
          let intersectingMunicipalities =
            await getAllMunicipalitiesBetweenAandB(
              result.between_locations.from,
              result.between_locations.to,
            );

          if (intersectingMunicipalities.length > 0) {
            areasDrawn = intersectingMunicipalities;
            store.dispatch(setAreaSelectionMode("area"));
            store.dispatch(setAvailableAreas(intersectingMunicipalities));

            // auto select the areas if there are only a few
            if (intersectingMunicipalities.length < 10) {
              store.dispatch(setSelectedAreas(intersectingMunicipalities));
              setSelectedDrawnDistricts(intersectingMunicipalities);
            }
          }
        }
        // else if result has areas draw those area polygons
        else if (result.areas && result.areas.length > 0) {
          store.dispatch(
            setGlobalLoadingMessage(
              i18n("Extracted location") +
                " " +
                result.areas.map((a) => a.name).join(", ") +
                ", " +
                i18n("drawing area") +
                "...",
            ),
          );
          this.props.multiAreaDraw(result.areas, {
            fetchProperties: true,
          });
          areasDrawn = [].concat(areasDrawn, result.areas);
        } else {
          zoomToBounds = false;
          // show the ai search hint if there are filters
          // else show the no results extracted message
          if (frontendFilters.length > 0) {
            this.setState({ aiSearchHintOpen: true });
            store.dispatch(setGlobalLoading(false));
          } else {
            store.dispatch(
              setGlobalLoadingMessage(i18n("No results extracted from query")),
            );

            return setTimeout(() => {
              store.dispatch(setGlobalLoading(false));
            }, WAITING_MESSAGING_TIME_AI_LOADER);
          }
        }

        // now we have all the areas drawn, we can calculate the bounds
        // of all the polygons and fit the map to the bounds
        if (zoomToBounds) {
          let polygons = areasDrawn.map((r) => r.geometry);

          // Create a LatLngBounds object to encapsulate all polygon coordinates
          const bounds = new window.google.maps.LatLngBounds();
          polygons.forEach((polygon) => {
            let coordinates = [];

            if (polygon.type === "MultiPolygon") {
              coordinates = polygon.coordinates.flat(2);
            } else {
              coordinates = polygon.coordinates[0];
            }

            coordinates.forEach((point) => {
              bounds.extend(
                new window.google.maps.LatLng(
                  parseFloat(point[1]),
                  parseFloat(point[0]),
                ),
              );
            });
          });

          this.props.map.fitBounds(bounds);
          setTimeout(() => {
            store.dispatch(setGlobalLoading(false));
            this.props.onShiftMapForPropertyPanel();
          }, WAITING_MESSAGING_TIME_AI_LOADER);
        }
      })
      .catch(() => {
        store.dispatch(setGlobalLoading(false));
      });
  }

  // url search entrypoint
  executeUrlSearch(urlString) {
    //remove query params
    let url = new URL(urlString);
    url.search = "";
    let urlWithoutQueryParams = url.toString();
    store.dispatch(setGlobalLoading(true));
    store.dispatch(setGlobalLoadingMessage(i18n("Fetching property...")));

    fetchPropertyByUrlRequest(urlWithoutQueryParams)
      .then((property) => {
        if (property) {
          window.open(
            `/${property.saleType == "rent" ? "rental" : "property"}/${property.id}`,
            property.id,
          );
        }
      })
      .finally(() => {
        store.dispatch(setGlobalLoading(false));
      });
  }

  // ref search entrypoint
  executeRefSearch(ref) {
    let id = convertRefToId(ref.trim());
    window.open(`/property/${id}`);
  }

  validateSearchType() {
    const { searchFocusIndex, searchResults, searchValue, searchType } =
      this.state;

    // search type is ai assistant so fire the ai filter request
    if (searchType === DROPDOWN_TYPE_AI_ASSISTANT) {
      this.executeAiSearch(searchValue);
      this.searchInput.current.blur();
      return;
    }

    // search type is url so open the desired property if it exists
    if (searchType === DROPDOWN_TYPE_URL) {
      this.executeUrlSearch(searchValue);
      return;
    }

    // search type is url so open the desired property if it exists
    if (searchType === DROPDOWN_TYPE_REF) {
      this.executeRefSearch(searchValue);
      return;
    }

    // use a google place search if the user hasnt selected any result
    if (searchFocusIndex === -1) {
      return this.onPlaceSearch(searchValue);
    }

    if (searchResults.length > 0 && searchFocusIndex < searchResults.length) {
      this.onLocationSelect(searchResults[Math.max(0, searchFocusIndex)]);
    }

    sendAnalyticsEvent("Search Bar Click", {
      type: "search",
      details: searchType,
    });
  }

  onClickInInput() {
    this.setState({ searchResults: [], searchValue: "" });
    document.getElementById("search-input").focus();
    sendAnalyticsEvent("Search Bar Click", {
      type: "input",
    });
  }

  /**
   * different to onSearchInputChange in that we listen specifically
   * to the return and arrow keys to fly to the selected zone on the map
   */
  onSearchInputKeyDown(e) {
    const { searchFocusIndex, searchResults, searchType } = this.state;

    // handle location select for selected search result index
    if (e.key === "Enter" || e.key === "Return") {
      this.validateSearchType();
    }

    // reset search bar state on escape
    if (e.key === "Escape") {
      this.setState({
        searchResults: [],
        searchFocusIndex: -1,
        searchValue: "",
      });
    }

    // only proceed if search type is by area
    if (searchType !== DROPDOWN_TYPE_LIST_OF_AREAS) {
      return;
    }

    // move the index of search result focus on keys up and down
    if (e.key === "ArrowUp") {
      this.setState({
        searchFocusIndex: Math.max(searchFocusIndex - 1, 0),
      });
    }

    if (e.key === "ArrowDown") {
      this.setState({
        searchFocusIndex: Math.min(
          searchFocusIndex + 1,
          searchResults.length - 1,
        ),
      });
    }
  }

  onSearchInputChange(e) {
    const { searchType } = this.state;

    this.setState(
      {
        searchValue: e.target.value,
      },
      () => {
        // only proceed if search type is by area
        if (searchType !== DROPDOWN_TYPE_LIST_OF_AREAS) {
          return;
        }

        // debounce the onSearchInputChange function for performance reasons
        const onSearchInputChangeDebounced = debounce(
          this.onPostSearchInputChange.bind(this),
          100,
        );
        onSearchInputChangeDebounced();
      },
    );
  }

  /**
   * when the search input changes value this function is fired
   * we crawl through our map search index local database and find any
   * matching names then we populate the state's searchResults variable
   * in order to populate the dropdown below the search bar
   */
  onPostSearchInputChange() {
    const { searchValue, searchType } = this.state;
    let normalizedSearchValue = normalizeString(searchValue);

    if (
      searchValue.length === 0 ||
      searchType !== DROPDOWN_TYPE_LIST_OF_AREAS
    ) {
      return this.setState({
        searchResults: [],
      });
    }

    if (!this.searchIndexes) {
      this.searchIndexes = getSearchIndexes();

      if (!this.searchIndexes) {
        return;
      }
    }

    let searchResults = [];
    let indexes = this.searchIndexes ? this.searchIndexes.indexes : [];
    indexes = indexes ?? [];

    for (let index of indexes) {
      let normalizedIndexName = normalizeString(index.name);

      // dont show autonomous communities or provinces
      if (
        index.type !== "municipality" &&
        index.type !== "locality" &&
        index.type !== "district" &&
        index.type !== "zone"
      ) {
        continue;
      }

      if (normalizedIndexName.includes(normalizedSearchValue)) {
        searchResults.push(index);
      } else {
        // if users search district with municipality to refine their search
        if (index.municipality) {
          let entireName = normalizeString(
            index.name + " " + index.municipality,
          );

          if (entireName.includes(normalizedSearchValue)) {
            searchResults.push(index);
          }
        }
      }
    }

    // sort results by similarity so the best matches are prioritised
    searchResults.sort((a, b) => {
      let indexA = normalizeString(a.name);
      let indexB = normalizeString(b.name);

      const wordsA = indexA.split(" ");
      const wordsB = indexB.split(" ");

      // Compare the first word of each name
      const firstWordA = wordsA[0];
      const firstWordB = wordsB[0];

      if (
        firstWordA.startsWith(normalizedSearchValue) &&
        !firstWordB.startsWith(normalizedSearchValue)
      ) {
        return -1; // a should come before b
      } else if (
        !firstWordA.startsWith(normalizedSearchValue) &&
        firstWordB.startsWith(normalizedSearchValue)
      ) {
        return 1; // b should come before a
      } else {
        // If both have the same prefix in the first word, compare the remaining words
        for (let i = 1; i < wordsA.length && i < wordsB.length; i++) {
          if (wordsA[i] < wordsB[i]) {
            return -1; // a should come before b
          } else if (wordsA[i] > wordsB[i]) {
            return 1; // b should come before a
          }
        }
        // If all words are the same or one string is a prefix of the other, sort by length
        return wordsA.length - wordsB.length;
      }
    });

    // performance: only show the first 25 results
    searchResults = searchResults.slice(0, 25);

    // prioritise municipalities over provinces
    if (searchResults.length > 1) {
      if (
        searchResults[0].name === searchResults[1].name &&
        searchResults[0].type === "province"
      ) {
        let province = searchResults[0];
        let mun = searchResults[1];
        searchResults[0] = mun;
        searchResults[1] = province;
      }
    }

    this.setState({
      searchResults,
      searchFocusIndex: -1,
    });
  }

  renderRegionChip(regionType) {
    if (regionType === "district") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _dis">
          {i18n("District")}
        </div>
      );
    }

    if (regionType === "locality") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _dis">
          {i18n("Locality")}
        </div>
      );
    }

    if (regionType === "zone") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _dis">
          {i18n("Zone")}
        </div>
      );
    }

    if (regionType === "municipality") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _mun">
          {i18n("Municipality")}
        </div>
      );
    }

    if (regionType === "province") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _prov">
          {i18n("Province")}
        </div>
      );
    }

    if (regionType === "autonomous_community") {
      return (
        <div className="search-bar_input-content_search-results_result_chip _auto">
          {i18n("Autonomous Community")}
        </div>
      );
    }
  }

  // render result name with character highlighting matching the search value
  renderResultName(result) {
    const { searchValue } = this.state;
    let inputValue = normalizeString(searchValue);
    let resultName = result.name;
    let resultNameNormalized = normalizeString(resultName);

    if (result.type === "district") {
      resultName = `${resultName}, ${result.municipality}`;
    }

    let indexOfMatch = resultNameNormalized.indexOf(inputValue);

    // no matching beginning sequence, return an unboldened string
    if (indexOfMatch !== 0) {
      return <span>{resultName}</span>;
    }

    // bolden the match
    let boldenString = resultName.substring(
      indexOfMatch,
      indexOfMatch + inputValue.length,
    );
    let restOfString = resultName.substring(
      indexOfMatch + inputValue.length,
      resultName.length,
    );
    return (
      <span>
        <strong>{boldenString}</strong>
        {restOfString}
      </span>
    );
  }

  renderSearchResult(result, index) {
    const { searchFocusIndex } = this.state;

    return (
      <li
        key={index}
        className={
          "search-bar_input-content_search-results_result" +
          (searchFocusIndex === index ? " active" : "")
        }
        onMouseEnter={() => this.setState({ searchFocusIndex: index })}
        onClick={() => this.onLocationSelect(result)}
      >
        {this.renderResultName(result)}
        {this.renderRegionChip(result.type)}
      </li>
    );
  }

  setSearchType(searchType) {
    this.setState(
      {
        searchType,
        dropdownOpen: false,
        searchResults: [],
      },
      () => {
        if (this.state.searchType === DROPDOWN_TYPE_LIST_OF_AREAS) {
          this.onPostSearchInputChange();
        }
      },
    );

    sendAnalyticsEvent("Search Bar Click", {
      type: "set type",
      searchType,
    });
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    const { saleType } = this.props.filters;
    const { dropdownOpen, searchResults, searchType, searchValue } = this.state;
    const hasSearchResults = searchResults.length > 0;

    return (
      <div className="search-bar-wrapper">
        <div className="search-bar">
          <div className="search-bar_toggle-content">
            <div
              onClick={() => this.onSaleTypeChange("sale")}
              className={`search-bar_toggle-btn sale ${saleType === "sale" && "selected"}`}
            >
              <span>{i18n("Sale")}</span>
            </div>
            <div className="vertical-divider"></div>
            <div
              onClick={() => this.onSaleTypeChange("rent")}
              className={`search-bar_toggle-btn rent ${saleType === "rent" && "selected"}`}
            >
              <span>{i18n("Rent")}</span>
            </div>
            <div className="vertical-divider"></div>
            <div
              onClick={() => this.onSaleTypeChange("sold")}
              className={`search-bar_toggle-btn sold ${saleType === "sold" && "selected"}`}
            >
              <span>{i18n("Sold")}</span>
            </div>
          </div>
          <div
            className={
              hasSearchResults
                ? "search-bar_container has-search-results"
                : "search-bar_container"
            }
          >
            <div className="search-bar_container_search-input">
              <div
                onClick={(e) => this.toggleDropdown(e)}
                className="search-bar_dropdown-toggler"
              >
                <span>{LIST_OF_DROPDOWN_ITEMS[searchType]()}</span>
                <img src={downChevronIcon} alt="" />
                {dropdownOpen && (
                  <ul className="search-bar_dropdown">
                    {Object.keys(LIST_OF_DROPDOWN_ITEMS).map((key, index) => (
                      <li key={index} onClick={() => this.setSearchType(key)}>
                        <span>{LIST_OF_DROPDOWN_ITEMS[key]()}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="search-bar_divider"></div>
              <div className="search-bar_input-content">
                <input
                  id="search-input"
                  ref={this.searchInput}
                  value={searchValue}
                  type="text"
                  onKeyDown={(e) => this.onSearchInputKeyDown(e)}
                  onChange={(e) => this.onSearchInputChange(e)}
                  onClick={(e) => this.onPostSearchInputChange()}
                  placeholder={SEARCH_TYPE_PLACEHOLDER_MAPPING[searchType]()}
                />
                {hasSearchResults && (
                  <ul className="search-bar_input-content_search-results">
                    {searchResults.map((result, index) =>
                      this.renderSearchResult(result, index),
                    )}
                  </ul>
                )}
                {searchValue && (
                  <div
                    className="search-bar_container_search-input_close-content"
                    onClick={() => {
                      this.onClickInInput();
                    }}
                  >
                    <img
                      className="search-bar_container_search-input_close"
                      alt="Close Icon"
                      src={closeIcon}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="search-bar_divider"></div>
            <div
              className="search-bar_container_search-icon-content"
              onClick={() => this.validateSearchType()}
            >
              <img src={searchIcon} alt="search icon" />
            </div>
          </div>
        </div>

        <AiSearchHintDialog
          open={this.state.aiSearchHintOpen}
          aiFilters={this.state.aiFilters}
          onClose={() => {
            this.setState({ aiSearchHintOpen: false });
          }}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  filters: state.filters,
  mapReducer: state.map,
}))(SearchBar);
