import { setAvailability } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import Button from "../ui/Button/Button";
import StatusChip from "./StatusChip";

const AvailabilityStatusContainer = ({ option, onSelect, selected }) => {
  const isSelected = option.status === selected?.status;

  return (
    <div
      onClick={() => onSelect()}
      className={`flex w-full cursor-pointer gap-3 rounded-lg border p-3 ${
        isSelected ? "border-black bg-[#2222221A]" : "border-[#BABABA]"
      }`}
    >
      <div
        className={`mt-1 flex h-6 w-6 min-w-6 items-center justify-center rounded-full border ${
          isSelected ? "border-black" : "border-[#BABABA]"
        }`}
      >
        {isSelected && <div className="h-3 w-3 rounded-full bg-black"></div>}
      </div>
      <div className="flex flex-col gap-1">
        <StatusChip status={option.status} />
        <p
          className={`text-sm ${isSelected ? "text-black" : "text-[#717171]"}`}
        >
          {i18n(option.text)}
        </p>
      </div>
    </div>
  );
};

const AvailabilityModal = ({ isOpen, closeModal }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { propertyId } = useSelector((state) => state.myProperties);
  const dispatch = useDispatch();

  const renderStatusOptions = () => {
    const STATUS_OPTIONS = [
      {
        status: "available",
        text: "Lorem ipsum dolar sit amet lorem ipsum dolar sit amet.",
      },
      {
        status: "reserved",
        text: "Lorem ipsum dolar sit amet lorem ipsum dolar sit amet.",
      },
      {
        status: "underContract",
        text: "Lorem ipsum dolar sit amet lorem ipsum dolar sit amet.",
      },
      {
        status: "sold",
        text: "Lorem ipsum dolar sit amet lorem ipsum dolar sit amet.",
      },
      {
        status: "offMarket",
        text: "Lorem ipsum dolar sit amet lorem ipsum dolar sit amet.",
      },
    ];

    return (
      <>
        {STATUS_OPTIONS.map((option) => (
          <AvailabilityStatusContainer
            key={option.status}
            option={option}
            onSelect={() => setSelectedOption(option)}
            selected={selectedOption}
          />
        ))}
      </>
    );
  };

  const handleSaveAvailability = async () => {
    closeModal();

    try {
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: { availability: selectedOption.status },
      });
      dispatch(setAvailability(selectedOption.status));
    } catch (e) {
      console.log(e);
      toast.error(
        i18n(
          `Failed to set availability to ${selectedOption.status}. Please try again.`,
        ),
        {
          duration: 5000,
        },
      );
    }

    toast.success(
      i18n(`Availability status set to: ${selectedOption.status}`),
      {
        duration: 5000,
      },
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <>
          <DialogHeader>
            <DialogTitle>{i18n("Availability Settings")}</DialogTitle>
          </DialogHeader>
          <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
            {renderStatusOptions()}
          </div>
          <DialogFooter>
            <div className="flex w-full justify-between">
              <Button onClick={() => closeModal()} variant="ghost">
                <div className="text-base font-medium">{i18n("Cancel")}</div>
              </Button>
              <Button onClick={() => handleSaveAvailability()}>
                <div className="text-base font-medium">{i18n("Save")}</div>
              </Button>
            </div>
          </DialogFooter>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AvailabilityModal;
