import { useState, useEffect } from "react";
import FullscreenContainer from "./FullscreenContainer";
import { i18n } from "i18n/localisation";

const GalleryView = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
    setSelectedImage(images[nextIndex]);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
    setSelectedImage(images[prevIndex]);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedImage) return;
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedImage, currentIndex]);

  if (selectedImage) {
    return (
      <div className="fixed inset-0 bg-black">
        <button
          onClick={() => setSelectedImage(null)}
          className="absolute left-4 top-4 rounded-lg bg-black/50 px-4 py-2 text-white"
        >
          {i18n("Back")}
        </button>
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 rounded-lg bg-black/50 px-4 py-2 text-white">
          {currentIndex + 1} / {images.length}
        </div>
        <button
          onClick={handlePrev}
          className="absolute left-4 top-1/2 -translate-y-1/2 rounded-lg bg-black/50 px-4 py-2 text-white"
        >
          ←
        </button>
        <button
          onClick={handleNext}
          className="absolute right-4 top-1/2 -translate-y-1/2 rounded-lg bg-black/50 px-4 py-2 text-white"
        >
          →
        </button>
        <img src={selectedImage} className="h-full w-full object-contain" />
      </div>
    );
  }

  return (
    <div className="relative grid grid-cols-2 gap-4 overflow-y-auto p-4 md:grid-cols-3 lg:grid-cols-4">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          onClick={() => {
            setSelectedImage(image);
            setCurrentIndex(index);
          }}
          className="h-64 w-full cursor-pointer rounded-lg object-cover transition-opacity hover:opacity-90"
        />
      ))}
    </div>
  );
};

export default GalleryView;
