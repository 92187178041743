import { createAction } from "@reduxjs/toolkit";
import { toast } from "sonner";
import {
  deleteCollectionRequest,
  fetchCollectionsRequest,
  saveCollectionRequest,
} from "../api/collections";
import { i18n } from "i18n/localisation";
import moment from "moment";
import { fetchUserNotifications } from "./notificationsActions";

export const setCollections = createAction("collections/set");

// TODO: move this to a utils file
// initial collection object
const COLLECTION_INITIAL = {
  properties: [], // array of property ids
  searches: [], // array of search objects [{ "id": "uuid", "filters": [], sort: {}, polygons: [] }]
  title: "",
  metadata: {}, // metadata objects { "notes": "" }
};

// get a copy of an initial collection object
function getCollectionInitial() {
  return { ...COLLECTION_INITIAL };
}

// fetches user's collections and adds them to the store
export function fetchCollections() {
  return async (dispatch, getState) => {
    try {
      const data = await fetchCollectionsRequest();
      dispatch(setCollections(data.collections));

      // fetch user notifications after collections are fetched
      // as they are related to collections/saved searches
      dispatch(fetchUserNotifications());
    } catch (e) {}
  };
}

export function saveCollection(collection) {
  return async (dispatch, getState) => {
    let collectionToSave = { ...collection };

    collectionToSave.properties = collection.properties.map((property) =>
      typeof property === "string" ? property : property.id,
    );

    await saveCollectionRequest(collectionToSave);
    dispatch(fetchCollections());
  };
}

// creates a new collection
export function createCollection(collectionData) {
  return async (dispatch, getState) => {
    const collection = { ...getCollectionInitial(), ...collectionData };
    await dispatch(saveCollection(collection));
    toast(i18n("Collection has been created."), { duration: 5000 });
  };
}

export function deleteCollection(collection) {
  return async (dispatch, getState) => {
    await deleteCollectionRequest(collection.id);
    toast(i18n("Collection has been deleted."), { duration: 5000 });
    dispatch(fetchCollections());
  };
}

export function saveMetaDataToCollection(collection, metadata) {
  return async (dispatch, getState) => {
    dispatch(saveCollection({ ...collection, metadata: metadata }));
    toast(i18n("Collection has been updated."), { duration: 5000 });
  };
}

export function addPropertyToCollection(collection, propertyId) {
  return async (dispatch, getState) => {
    // Only add if the property isn't already in the collection
    if (!collection.properties.includes(propertyId)) {
      dispatch(
        saveCollection({
          ...collection,
          properties: [...collection.properties, propertyId],
        }),
      );
    }
  };
}

export function removePropertyFromCollection(collection, propertyId) {
  return async (dispatch, getState) => {
    let propertyIds = collection.properties.filter(
      (property) => property.id !== propertyId,
    );
    dispatch(saveCollection({ ...collection, properties: propertyIds }));
  };
}

export function addSearchToCollection(collection, search) {
  return async (dispatch, getState) => {
    let searches = collection.searches.filter((s) => s.id !== search.id);
    let searchToAdd = { ...search };

    if (!searchToAdd.creationDate) {
      searchToAdd.creationDate = moment().unix();
    }

    dispatch(
      saveCollection({ ...collection, searches: [...searches, searchToAdd] }),
    );
  };
}

export function removeSearchFromCollection(collection, search) {
  return async (dispatch, getState) => {
    let searches = collection.searches.filter((s) => s.id !== search.id);
    dispatch(saveCollection({ ...collection, searches: searches }));
  };
}
