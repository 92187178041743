import { getLang, i18n } from "i18n/localisation";
import { getSearchIndexes } from "../actions/geoActions";
import { GOOGLE_MAPS_API_KEY } from "../config/constants";
import store from "store";
import { setInitialDataLoaded } from "actions/miscActions";
import { getCheckoutUrl, modifySubscription } from "api/subscriptions";
import { getDistrictsByMunCode } from "db/mapStore";
import { v4 as uuidv4 } from "uuid";
import { generateThumbnailForSearch } from "./file";
import moment from "moment";
import levenshtein from "js-levenshtein";
import { getCatastralByRefDirect } from "api/cma";

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

// Remove accents and convert to lowercase
export function normalizeString(str) {
  return str
    .normalize("NFD") // Normalize the string with diacritics to base characters
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .toLowerCase(); // Convert to lowercase
}

export function capitalizeWord(word) {
  // Check if the input is a valid string
  if (typeof word !== "string" || word.length === 0) {
    return word;
  }

  // Capitalize the first letter and concatenate with the rest of the word
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// fetch all districts/localities under a municipality
export async function getDistrictsForMunicipality(municipalityCode) {
  return await getDistrictsByMunCode("es", municipalityCode);
}

// gets price per square meter
export function getPricePerM2(property) {
  return Math.round(parseFloat(property.price) / parseFloat(property.size));
}

// gets the price change percentage from the last price
export function getPriceChangePercentage(property) {
  if (!property.priceHistory || property.priceHistory.length == 0) {
    return 0;
  }

  let priceDrop =
    parseFloat(property.price) -
    parseFloat(property.priceHistory[property.priceHistory.length - 1].price);

  return Math.round(
    (parseFloat(priceDrop) /
      parseFloat(
        property.priceHistory[property.priceHistory.length - 1].price,
      )) *
      100,
  );
}

// gets the drop of price in percentage
export function getPriceDropPercentage(property) {
  if (!property.priceDropValue) {
    return 0;
  }

  return Math.round(
    (parseFloat(property.priceDropValue) / parseFloat(property.price)) * 100,
  );
}

// gets center of latlng polygon
export function getCentroid(coords) {
  let coordinates = coords;

  // Check if the polygon is closed
  if (
    coordinates.length > 0 &&
    coordinates[0] !== coordinates[coordinates.length - 1]
  ) {
    coordinates = [].concat(coordinates, [coordinates[0]]);
  }

  let centroidX = 0,
    centroidY = 0,
    area = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    let x1 = coordinates[i].longitude;
    let y1 = coordinates[i].latitude;
    let x2 = coordinates[i + 1].longitude;
    let y2 = coordinates[i + 1].latitude;
    let tempArea = x1 * y2 - x2 * y1;
    area += tempArea;
    centroidX += (x1 + x2) * tempArea;
    centroidY += (y1 + y2) * tempArea;
  }

  area /= 2;
  centroidX /= 6 * area;
  centroidY /= 6 * area;

  return {
    latitude: centroidY,
    longitude: centroidX,
  };
}

export function toCapitalCase(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }
  return str
    .split(" ")
    .map((word) => capitalizeWord(word))
    .join(" ");
}

export function extractZipCode(address) {
  const zipCodeRegex = /\b\d{5}\b/g;
  const match = address.match(zipCodeRegex);
  return match ? match[0] : null;
}

export function removeZipCode(address) {
  const zipCodeRegex = /\b\d{5}\b/g;
  return address.replace(zipCodeRegex, "");
}

export function getMunicipalityByDistrict(district) {
  return getSearchIndexes().indexes.find(
    (idx) => idx.mun_code === district.mun_code && idx.type === "municipality",
  );
}

export function getTextInBrackets(text) {
  const regex = /\(([^)]+)\)/;
  const match = text.match(regex);
  return match ? match[1] : null;
}

export function extractNumbersFromString(str) {
  const numbers = str.match(/\d+/g);
  return numbers ? numbers.join("") : "";
}

export function getDataUrl(inputFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(inputFile);
  });
}

// idealista has separate urls for images with and without watermarks
export function unwatermarkImg(url) {
  if (
    url.startsWith("https://img3.idealista.com") ||
    url.startsWith("https://img4.idealista.com")
  ) {
    return url.replace("WEB_DETAIL", "WEB_DETAIL_TOP-L-L");
  }

  return url;
}

// resolves the title of a search object
export function getSearchTitle(search) {
  if (search.title) {
    return search.title;
  }

  let names = [];

  for (let polygon of search.polygons) {
    if (polygon.metadata) {
      names.push(polygon.metadata.name);
    }
  }

  if (names.length == 0) {
    return i18n("Custom area");
  }

  return names.join(", ");
}

export function getSearchSubtitle(search) {
  let filterCount = search.filters.length;
  filterCount += search.invisibleFilters.length;
  let areaCount = search.polygons.length;
  return (
    filterCount +
    " " +
    (filterCount == 1 ? i18n("filter") : i18n("filters")) +
    " " +
    i18n("and") +
    " " +
    areaCount +
    " " +
    (areaCount == 1 ? i18n("area") : i18n("areas"))
  );
}

// opens subscription payment gateway stripe session
export function subscribeToPlatform(
  plan = "standard",
  interval = "monthly",
  seats = 1,
) {
  const user = store.getState().auth.user;
  store.dispatch(setInitialDataLoaded(false));
  window.location.href = getCheckoutUrl(user.sub, plan, interval, seats);
}

// formats numbers with commas or dots depending on locale
export function formatNumber(num) {
  let locale = getLang().code;

  if (locale == "en") {
    locale = "en-US";
  }

  if (locale == "es") {
    locale = "es-ES";
  }

  return parseFloat(num).toLocaleString(locale);
}

// converts a reference number from reference to a property id
export function convertRefToId(ref) {
  let lastChars = ref.slice(-3);

  if (lastChars == "001") {
    lastChars = "i";
  } else if (lastChars == "002") {
    lastChars = "f";
  }

  return ref.slice(1, -3) + "-" + lastChars;
}

// converts a property id to a ref number
export function convertIdToRef(id) {
  let lastChars = id.slice(-1);

  if (lastChars == "i") {
    lastChars = "001";
  } else if (lastChars == "f") {
    lastChars = "002";
  }

  return "i" + id.slice(0, -2) + lastChars;
}

// reset url without reloading page
export function resetUrlSearchQueries() {
  const url = new URL(window.location);
  url.search = ""; // Clear the search queries
  window.history.pushState({}, "", url);
}

// helper function to round to nearest 1000
export function roundToNearest1000(num) {
  return Math.round(num / 1000) * 1000;
}

// helper function to round to nearest 100
export function roundToNearest100(num) {
  return Math.round(num / 100) * 100;
}

// helper function to round to nearest 50
export function roundToNearest50(num) {
  return Math.round(num / 50) * 50;
}

// we need to create certain polygon refs for saved searches
// so we don't exceed the 400kb dynamodb row limit
// the refs would point to the polygon in the GeoBoundaries table on AWS
// or the polygon in the indexedDB mapStore on the frontend
export function createPolygonRefs(polygons) {
  return polygons.map((p) => {
    let ref = { ...p };

    if (ref.metadata && ref.metadata.id) {
      ref = {
        ref: ref.metadata.id,
        country: ref.metadata.country,
        type: ref.metadata.type,
      };
    }

    return ref;
  });
}

// helper function to get a serialized search object from the state
export async function getSerializedSearchObject(searchId) {
  let id = searchId;

  if (!id) {
    id = uuidv4();
  }

  let filters = store.getState().filters;
  let drawnPolygons = store.getState().polygon.drawnPolygons;
  let thumbnail = await generateThumbnailForSearch(id, drawnPolygons);

  return {
    id,
    filters: [...filters.filters].map((f) => {
      let skimmedFilter = { ...f };
      if (skimmedFilter.icon) {
        delete skimmedFilter.icon;
      }

      if (skimmedFilter.highlightedIcon) {
        delete skimmedFilter.highlightedIcon;
      }

      return skimmedFilter;
    }),
    invisibleFilters: [],
    buildingType: filters.buildingType,
    saleType: filters.saleType,
    sort: { ...filters.sort },
    polygons: createPolygonRefs(drawnPolygons),
    objectType: "search",
    thumbnail,
  };
}

// get unread notifications
export function getUnreadNotifications() {
  let notifications = store.getState().notifications.notifications;

  return notifications.filter(
    (n) => n.status == "unread" || n.notification_status == "unread",
  );
}

// get unread notifications
export function getReadNotifications() {
  let notifications = store.getState().notifications.notifications;

  return notifications.filter(
    (n) => n.status == "read" || n.notification_status == "read",
  );
}

// get collection by id
export function getCollectionById(id) {
  const collections = store.getState().collections.collections;
  return collections.find((c) => c.id === id);
}

// get search by id
export function getSearchById(id) {
  const collections = store.getState().collections.collections;

  for (let collection of collections) {
    let search = collection.searches.find((s) => s.id === id);
    if (search) {
      return search;
    }
  }
}

// return time since timestamp in human readable format
export function formatTimestampByRelativeTime(timestamp) {
  let relativeTimeString = moment.unix(timestamp).fromNow(true);

  if (relativeTimeString.includes("days")) {
    return relativeTimeString
      .replace("day", "d")
      .replace("ds", "d")
      .replace(" ", "");
  }

  if (relativeTimeString.includes("hours")) {
    return relativeTimeString
      .replace("hour", "h")
      .replace("hs", "h")
      .replace(" ", "");
  }

  if (relativeTimeString.includes("minutes")) {
    return relativeTimeString
      .replace("minute", "m")
      .replace("ms", "m")
      .replace(" ", "");
  }

  if (relativeTimeString.includes("seconds")) {
    return relativeTimeString
      .replace("second", "s")
      .replace("ss", "s")
      .replace(" ", "");
  }

  return moment.unix(timestamp).format("DD/MM/YYYY");
}

// hacky but required to adjust search bar location in relation to side panel
export function adjustSearchBarAndMapTools(showPanel) {
  let searchBarWrapper = document.querySelector(".search-bar-wrapper");
  let mapToolsContainer = document.querySelector(".map-toolbar-container");

  if (showPanel) {
    if (searchBarWrapper) searchBarWrapper.classList.add("with-side-panel");
    if (mapToolsContainer) mapToolsContainer.classList.add("with-side-panel");
  } else {
    if (searchBarWrapper) searchBarWrapper.classList.remove("with-side-panel");
    if (mapToolsContainer)
      mapToolsContainer.classList.remove("with-side-panel");
  }
}

export const debouncedMapSetZoom = debounce((map, zoomLevel) => {
  map.setZoom(zoomLevel);
}, 200);

export const debouncedMapPanTo = debounce((map, latLng) => {
  map.panTo(latLng);
}, 200);

// true if the current resolution is within the mobile breakpoint
export const isMobile = () => {
  return window.innerWidth < 1100;
};

export function initGTag() {
  // Initialize Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NBT6X3CV");

  // Load the gtag.js script dynamically
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-DVP5LKF2NZ";
  script.async = true;
  document.head.appendChild(script);

  // Initialize Google Tag Manager
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  // Add the configuration after script is loaded
  script.onload = function () {
    gtag("js", new Date());
    gtag("config", "G-DVP5LKF2NZ");
  };
}

// convert ai filters response payload to human readable mapping
// for frontend list display
export function aiFiltersToHumanReadableMapping(filters = {}) {
  const mapping = {
    bathrooms: "Bathrooms",
    between_locations: "Between Locations",
    buildable_floors: "Buildable Floors",
    buildable_size: "Buildable Size",
    building_garageSpaces: "Building Garage Spaces",
    building_numLifts: "Number of Lifts in Building",
    building_use: "Building Use",
    commercialActivities: "Commercial Activities",
    commercialType: "Commercial Type",
    districts: "Districts",
    hasAirConditioning: "Has Air Conditioning",
    hasAlarmSystem: "Has Alarm System",
    hasAutomaticDoor: "Has Automatic Door",
    hasBalcony: "Has Balcony",
    hasCCTV: "Has CCTV",
    hasCentralHeating: "Has Central Heating",
    hasElectricity: "Has Electricity",
    hasFittedWardrobes: "Has Fitted Wardrobes",
    hasFullyEquippedKitchen: "Has Fully Equipped Kitchen",
    hasGarage: "Has Garage",
    hasGarden: "Has Garden",
    hasGreenery: "Has Greenery",
    hasKitchen: "Has Kitchen",
    hasLift: "Has Lift",
    hasLoadingBay: "Has Loading Bay",
    hasNaturalGas: "Has Natural Gas",
    hasOffice: "Has Office",
    hasRoadAccess: "Has Road Access",
    hasSafetyGate: "Has Safety Gate",
    hasSeaView: "Has Sea View",
    hasSecurity: "Has Security",
    hasSewage: "Has Sewage",
    hasStorage: "Has Storage",
    hasStoreroom: "Has Storeroom",
    hasStreetLighting: "Has Street Lighting",
    hasSwimmingPool: "Has Swimming Pool",
    hasTerrace: "Has Terrace",
    hasToilets: "Has Toilets",
    hasWater: "Has Water",
    is24hAccess: "Has 24-Hour Access",
    isOnCorner: "Is On Corner",
    isPrivateBankProperty: "Is Private Bank Property",
    landClassification: "Land Classification",
    landType: "Land Type",
    min_sale_size: "Minimum Sale Size",
    municipalities: "Municipalities",
    numFloors: "Number of Floors",
    price_range: "Price Range",
    propertyType: "Property Type",
    rooms: "Number of Rooms",
    sellerType: "Seller Type",
    size: "Size",
    sort: "Sort",
    streets: "Streets",
    tags: "Tags",
    saleType: "Sale Type (Rent/Sale)",
  };

  return Object.keys(filters)
    .map((key) => {
      return {
        id: key,
        label: i18n(mapping[key]),
        value: filters[key],
      };
    })
    .filter((f) => {
      if (Array.isArray(f.value)) {
        return f.value.length > 0;
      }
      return !!f.value;
    });
}

// get the best match location from a list of locations
export function getBestMatchLocation(locations, searchValue) {
  if (!locations || !searchValue || locations.length === 0) {
    return null;
  }

  // First try exact match after normalization
  const exactMatch = locations.find(
    (l) =>
      normalizeString(l.name) === normalizeString(searchValue).trim() &&
      l.type === "municipality",
  );

  if (exactMatch) {
    return {
      ...exactMatch,
      score: 1,
    };
  }

  // Calculate similarity scores
  const scoredLocations = locations.map((location) => {
    const normalizedLocationName = normalizeString(location.name);
    const normalizedSearchValue = normalizeString(searchValue).trim();
    let normalizedFullLocationName = normalizedLocationName;

    if (location.type !== "municipality") {
      normalizedFullLocationName = normalizeString(
        `${location.name} ${location.municipality}`,
      );
    }

    // Calculate Levenshtein distance
    const distance = levenshtein(normalizedLocationName, normalizedSearchValue);
    const distanceFull = levenshtein(
      normalizedFullLocationName,
      normalizedSearchValue,
    );

    // Score is inverse of distance (closer to 0 is better)
    // Normalize by max possible distance (length of longer string)
    const maxLength = Math.max(
      normalizedLocationName.length,
      normalizedSearchValue.length,
    );
    let score = 1 - distance / maxLength;
    const scoreFull = 1 - distanceFull / maxLength;

    if (scoreFull > score) {
      score = scoreFull;
    }

    return {
      ...location,
      score,
    };
  });

  // Sort by score descending and return best match
  scoredLocations.sort((a, b) => b.score - a.score);
  return scoredLocations[0];
}

// gets the catastro url from the catastro api and open it in a new tab
export async function openCatastroRef(ref) {
  let catastro = await getCatastralByRefDirect(ref);
  let fullRef = ref;

  // from a multi ref catastro source so fill out entire ref
  if (catastro.rc && catastro.rc.pc1) {
    let rc = catastro.rc;
    fullRef = rc.pc1 + rc.pc2 + rc.car + rc.cc1 + rc.cc2;
  }

  // build full ref from this idbi.rc object
  if (catastro.idbi && catastro.idbi.rc) {
    let rc = catastro.idbi.rc;
    fullRef = rc.pc1 + rc.pc2 + rc.car + rc.cc1 + rc.cc2;
  }

  // if the dt is not present, we need to use the multi ref url
  if (!catastro.dt) {
    return window.open(
      `https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?RC1=${ref.substring(0, 7)}&RC2=${ref.substring(7, 14)}&RC3=&RC4=`,
      fullRef,
    );
  }

  window.open(
    `https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?del=${catastro.dt.loine.cp}&mun=${catastro.dt.cmc}&RefC=${fullRef}`,
    fullRef,
  );
}

export function formatPrice(price, currency = "EUR") {
  return price.toLocaleString("es-ES", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

// generate a random number between a and b with a step
export function randomNumberWithStep(a, b, step) {
  // Ensure a is smaller than b
  if (a > b) {
    [a, b] = [b, a];
  }

  // Calculate number of possible steps
  const steps = Math.floor((b - a) / step);

  // Generate random step count and calculate final value
  const randomStep = Math.floor(Math.random() * steps);
  return a + randomStep * step;
}
