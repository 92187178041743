/**
 * Grid Media Tiles component for drag and drop functionality
 * Adapted from dnd-kit's Advanced Pages example
 * @see https://github.com/clauderic/dnd-kit/blob/master/stories/3%20-%20Examples/Advanced/Pages/Pages.tsx
 */

import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  useDndContext,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS, isKeyboardEvent } from "@dnd-kit/utilities";
import classNames from "classnames";
import { useEffect, useState } from "react";

import {
  setFloorPlans,
  setPhotos,
  setVideos,
  setVirtualTours,
} from "actions/myPropertiesActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./GridMediaTile.module.css";
import { MediaTile, Position } from "./MediaTile";

export function createRange(length, initializer) {
  return [...new Array(length)].map((_, index) => initializer(index));
}

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
  sideEffects: defaultDropAnimationSideEffects({
    className: {
      active: styles.active,
    },
  }),
};

export function GridMediaTiles({ layout, images, tab, onFileSelect }) {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(() =>
    createRange(images.length, (index) => `${index}`),
  );
  const dispatch = useDispatch();
  const media = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    virtualTours: state.myProperties.virtualTours,
    floorPlans: state.myProperties.floorPlans,
  }));
  const activeIndex = activeId ? items.indexOf(activeId) : -1;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setItems(() => createRange(images.length, (index) => `${index}`));
  }, [images]);

  const setMedia = {
    photos: (photos) => dispatch(setPhotos(photos)),
    videos: (videos) => dispatch(setVideos(videos)),
    virtualTours: (virtualTour) => dispatch(setVirtualTours(virtualTour)),
    floorPlans: (floorPlan) => dispatch(setFloorPlans(floorPlan)),
  };

  const handleRemove = (id) => {
    setItems((items) => items.filter((itemId) => itemId !== id));
    setMedia[tab.key](media[tab.key].filter((p) => p !== images[id]));
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
    >
      <SortableContext items={items}>
        <ul className={classNames(styles.Pages, styles[layout])}>
          {items.map((id, index) => (
            <SortablePage
              id={id}
              index={index + 1}
              key={id}
              layout={layout}
              activeIndex={activeIndex}
              onRemove={() => handleRemove(id)}
              image={images[id]}
            />
          ))}
          <MediaTile
            id={`${images.length}`}
            index={images.length}
            layout={layout}
            image={images[images.length]}
            tab={tab}
            onFileSelect={onFileSelect}
          />
        </ul>
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeId ? (
          <PageOverlay
            id={activeId}
            layout={layout}
            items={items}
            photos={images}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart({ active }) {
    setActiveId(active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragEnd({ over }) {
    if (over) {
      const overIndex = items.indexOf(over.id);

      if (activeIndex !== overIndex) {
        const newIndex = overIndex;

        setItems((items) => arrayMove(items, activeIndex, newIndex));
      }
    }

    setActiveId(null);
  }
}

function PageOverlay({ id, items, photos, ...props }) {
  const { activatorEvent, over } = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = items.indexOf(id);
  const overIndex = over?.id ? items.indexOf(over?.id) : -1;

  return (
    <MediaTile
      id={id}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
      image={photos[id]}
    />
  );
}

function SortablePage({ id, activeIndex, photos, ...props }) {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    over,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: always,
  });

  return (
    <MediaTile
      ref={setNodeRef}
      photos={photos}
      id={id}
      active={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      insertPosition={
        over?.id === id
          ? index > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
      {...props}
      {...attributes}
      {...listeners}
    />
  );
}

function always() {
  return true;
}
