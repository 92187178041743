import { useEffect, useState } from "react";
import checkIcon from "../../assets/core/check.svg";

const DropdownList = (props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedIndices, setSelectedIndices] = useState([]);
  let className = "dropdown-list";

  if (props.isInverted) {
    className += " inverted";
  }

  if (props.noMaxHeight) {
    className += " no-max-height";
  }

  // if a default value is provided then set the selected index to
  // the default value or values for multi select dropdowns
  useEffect(() => {
    if (props.defaultValue || props.selectedIds) {
      let currentIndex = 0;
      let indices = [];

      while (currentIndex < props.items.length) {
        if (props.isMulti) {
          for (let id of props.selectedIds) {
            if (id === props.items[currentIndex].id) {
              indices.push(currentIndex);
            }
          }
        } else {
          if (props.items[currentIndex].value === props.defaultValue) {
            // account for cancel button if its present
            if (props.cancelButton) {
              currentIndex++;
            }

            setSelectedIndices([currentIndex]);
            break;
          }
        }

        currentIndex++;
      }

      if (props.isMulti) {
        setSelectedIndices(indices);
      }
    }
  }, []);

  const onSelectDropdownItem = (e, index, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.onSelect) {
      item.onSelect();
    }

    if (props.isMulti) {
      let newSelectedIndices = [];

      if (selectedIndices.includes(index)) {
        newSelectedIndices = selectedIndices.filter((i) => i != index);
      } else {
        newSelectedIndices = [].concat(selectedIndices, [index]);
      }

      setSelectedIndices(newSelectedIndices);
    } else {
      setSelectedIndices([index]);
      props.setIsActive(false);
    }

    if (item.cancel) {
      props.setIsActive(false);
    }
  };

  const renderDropdownItem = (item, index, isCancelButton) => {
    let className = "dropdown-list_item";
    let isSelected = false;

    if (index === hoveredIndex) {
      className += " hovered";
    }

    if (selectedIndices.includes(index)) {
      isSelected = true;
    }

    if (item && props.selected === item.value) {
      isSelected = true;
    }

    if (isCancelButton) {
      return (
        <div
          key={0}
          className={className + " cancel"}
          onMouseOver={() => setHoveredIndex(index)}
          onClick={() => props.onCancel()}
        >
          <span>Cancel</span>
        </div>
      );
    }

    return (
      <div
        key={index}
        className={className}
        onMouseOver={() => setHoveredIndex(index)}
        onClick={(e) => onSelectDropdownItem(e, index, item)}
      >
        <span>{item.label}</span>
        {isSelected && <img src={checkIcon} />}
      </div>
    );
  };

  return (
    <div className={className}>
      {props.cancelButton && renderDropdownItem(null, 0, true)}
      {props.items.map((item, index) =>
        renderDropdownItem(item, props.cancelButton ? index + 1 : index, false),
      )}
    </div>
  );
};

export default DropdownList;
