import { useSelector } from "react-redux";
import checkIcon from "../../assets/core/check.svg";
import checkIconWhite from "../../assets/core/check_white.svg";
import downChevron from "../../assets/core/down_chevron.svg";

const Accordion = ({ section, isChecked, isOpen, onToggle }) => {
  const uploadPropertyData = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    floorPlans: state.myProperties.floorPlans,
    virtualTours: state.myProperties.virtualTours,
    price: state.myProperties.price,
    bedrooms: state.myProperties.bedrooms,
    bathrooms: state.myProperties.bathrooms,
    size: state.myProperties.size,
    features: state.myProperties.features,
    detailedType: state.myProperties.detailedType,
    description: state.myProperties.description,
    location: state.myProperties.municipality,
  }));

  const uncheckedSteps = section.steps?.filter(
    (step) =>
      !uploadPropertyData[step.key] ||
      uploadPropertyData[step.key].length === 0,
  );

  const numberOfSteps = section.steps.length;
  const completedSteps = numberOfSteps - uncheckedSteps.length;

  return (
    <div
      className={`checklist_accordion${isOpen ? " open" : ""}`}
      onClick={onToggle}
    >
      <div className="checklist_accordion_header">
        <div
          className={`checklist_accordion_image${isChecked ? " checked" : ""}`}
        >
          <img src={checkIconWhite} alt="check" />
        </div>
        <div className="checklist_accordion_title">
          <h3>{section.title}</h3>
          <p>
            {completedSteps}/{numberOfSteps}
          </p>
        </div>
        <div
          className={`checklist_accordion_arrow-icon${isOpen ? " open" : ""}`}
        >
          <img src={downChevron} alt="arrow" />
        </div>
      </div>

      <div className={`checklist_accordion_steps${isOpen ? " open" : ""}`}>
        {section.steps.map((step, index) => (
          <div
            key={step.key}
            className={`checklist_accordion_step${
              uncheckedSteps.includes(step) ? " unchecked" : " line-through"
            }${isOpen ? " visible" : ""}`}
            style={{
              transitionDelay: `${index * 0.05}s`,
            }}
          >
            <img
              src={checkIcon}
              alt="check"
              style={{
                visibility: uncheckedSteps.includes(step)
                  ? "hidden"
                  : "visible",
              }}
            />
            <p>{step.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
