import { ReactComponent as CamIcon } from "assets/core/cam.svg";
import { ReactComponent as FloorplanIcon } from "assets/core/floorplan.svg";
import { ReactComponent as ImageIcon } from "assets/core/image.svg";
import { ReactComponent as VirtualTourIcon } from "assets/core/virtualTour.svg";
import { i18n } from "i18n/localisation";

export const ACCORDION_SECTIONS = [
  {
    key: "media",
    title: "Media",
    steps: [
      { label: "Upload photo*", key: "photos", isRequired: true },
      { label: "Upload video", key: "videos", isRequired: false },
      { label: "Upload 360 image", key: "virtualTours", isRequired: false },
      { label: "Upload floor plan", key: "floorPlans", isRequired: false },
    ],
  },
  {
    key: "propertyDetails",
    title: "Details",
    steps: [
      { label: "Price*", key: "price", isRequired: true },
      { label: "Property subtype*", key: "detailedType", isRequired: true },
      { label: "No. of bedrooms*", key: "bedrooms", isRequired: true },
      { label: "No. of bathrooms*", key: "bathrooms", isRequired: true },
      { label: "Built area*", key: "size", isRequired: true },
      { label: "Features*", key: "features", isRequired: true },
    ],
  },
  {
    key: "description",
    title: "Description",
    steps: [
      { label: "Property description", key: "description", isRequired: false },
    ],
  },
  {
    key: "location",
    title: "Location",
    steps: [{ label: "Property Address*", key: "location", isRequired: true }],
  },
];

export const MODAL_STEPS = {
  PROPERTY_DETAILS: 1,
  CATASTRO: 2,
};

export const MODAL_STEPS_CONFIG = {
  [MODAL_STEPS.PROPERTY_DETAILS]: {
    title: i18n("Upload a new property"),
    description: i18n(
      "Select the listing type and property type to get started with your listing",
    ),
    validate: (formData) =>
      Boolean(formData.propertyType && formData.listingType),
  },
  [MODAL_STEPS.CATASTRO]: {
    title: i18n("Click or Type Catastral Reference"),
    description: i18n(
      "Showing exact location increases sale rate by an average of X amount",
    ),
    validate: (formData) => formData.isApproximateLocation !== null,
  },
};

export const MEDIA_TABS = [
  { key: "photos", label: i18n("Photo's"), icon: ImageIcon },
  { key: "videos", label: i18n("Video's"), icon: CamIcon },
  { key: "virtualTours", label: i18n("360 Images"), icon: VirtualTourIcon },
  { key: "floorPlans", label: i18n("Floorplans"), icon: FloorplanIcon },
];

export const ICON_CONFIG = {
  photos: { icon: ImageIcon, parameter: "stroke" },
  videos: { icon: CamIcon, parameter: "stroke" },
  virtualTours: { icon: VirtualTourIcon, parameter: "fill" },
  floorPlans: { icon: FloorplanIcon, parameter: "fill" },
};

export const NO_MEDIA_CONTAINER = {
  photos: {
    key: "photos",
    label: i18n("Add images"),
    text: i18n("Upload up to 100 images up to 32MB each in png or jpeg"),
  },
  videos: {
    key: "videos",
    label: i18n("Add videos"),
    text: i18n("Upload up to 10 videos up to 100MB each in mp4"),
  },
  virtualTours: {
    key: "virtualTours",
    label: i18n("Add 360 images"),
    text: i18n("Upload up to 10 images up to 32MB each in png or jpeg"),
  },
  floorPlans: {
    key: "floorPlans",
    label: i18n("Add floor plans"),
    text: i18n("Upload up to 10 images up to 32MB each in png or jpeg"),
  },
};

export const LOCATION_PREFERENCE_ITEMS = [
  {
    label: "Show exact location",
    value: false,
  },
  { label: "Show approximate location", value: true },
];
