export const generateVideoThumbnail = (videoFile) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.load();
    video.addEventListener("loadeddata", () => {
      // Seek the frame
      video.currentTime = 2;
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnailUrl = canvas.toDataURL("image/png");
      URL.revokeObjectURL(video.src);

      resolve({
        localUrl: thumbnailUrl,
        file: canvas,
      });
    });
  });
};

export const getAcceptedFileTypes = (tab) => {
  switch (tab?.key) {
    case "photos":
    case "floorPlans":
    case "virtualTours":
      return "image/jpeg,image/png";
    case "videos":
      return "video/mp4";
    default:
      return "";
  }
};
