const StatusChip = ({
  label,
  style,
  status,
  backgroundColor,
  textColor,
  checkImg = false,
}) => {
  const STATUS_CONFIG = {
    available: {
      status: "available",
      label: "Available",
      style: "bg-status-available text-white",
    },
    reserved: {
      status: "reserved",
      label: "Reserved",
      style: "bg-status-reserved text-white",
    },
    underContract: {
      status: "underContract",
      label: "Under Contract",
      style: "bg-status-under-contract text-white",
    },
    sold: {
      status: "sold",
      label: "Sold",
      style: "bg-status-sold text-white",
    },
    offMarket: {
      status: "offMarket",
      label: "Off-Market",
      style: "bg-status-off-market text-white",
    },
    published: {
      status: "published",
      label: "Published",
      style: "bg-status-chip-published text-status-label-published",
    },
    unpublished: {
      status: "unpublished",
      label: "Unpublished",
      style: "bg-status-chip-unpublished text-status-label-unpublished",
    },
  };

  return (
    <div
      className={`flex h-8 w-fit items-center justify-center rounded-full px-3 ${STATUS_CONFIG[status]?.style}`}
    >
      <p className="text-4 font-semibold">{STATUS_CONFIG[status]?.label}</p>
    </div>
  );
};

export default StatusChip;
