import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";
import { Storage } from "aws-amplify";
import { i18n } from "i18n/localisation";
import store from "store";
import * as turf from "@turf/turf";
import { normalizeGeoJsonCoordsToFloat } from "utils/polygon";

// Update this to update map data
export const CURRENT_MAP_DATA_VERSION = 23;
const MAP_DB_NAME = "mapData";

export async function mapDbExists() {
  const dbs = await indexedDB.databases();
  return dbs.find((db) => db.name === MAP_DB_NAME);
}

// function to index map data into IndexedDB
function indexMapData(countryCode, data) {
  return new Promise((resolve, reject) => {
    // simplify indexed geometries for faster rendering
    const simplifyFn = (d) => {
      let geom = d.geometry;

      try {
        geom = turf.simplify(normalizeGeoJsonCoordsToFloat(d.geometry), {
          tolerance: 0.001,
          highQuality: false,
        });
      } catch (e) {
        console.warn("Error simplifying geometry", e);
      }

      return {
        ...d,
        geometry: geom,
      };
    };

    const municipalities = data
      .filter((d) => d.type === "municipality")
      .map(simplifyFn);
    const provinces = data.filter((d) => d.type === "province").map(simplifyFn);
    const districts = data.filter(
      (d) =>
        d.type === "district" || d.type === "locality" || d.type === "zone",
    );

    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);
    const storeName = countryCode + "-municipalities";
    const provinceStoreName = countryCode + "-provinces";
    const districtStoreName = countryCode + "-districts";

    dbPromise.onupgradeneeded = function (event) {
      const db = event.target.result;

      if (db.objectStoreNames.contains(storeName)) {
        db.deleteObjectStore(storeName); // delete the existing object store
      }

      if (db.objectStoreNames.contains(provinceStoreName)) {
        db.deleteObjectStore(provinceStoreName);
      }

      if (db.objectStoreNames.contains(districtStoreName)) {
        db.deleteObjectStore(districtStoreName);
      }

      // create a new object store
      const objectStore = db.createObjectStore(storeName, { keyPath: "id" });
      objectStore.createIndex("lat_lng", ["center_lat", "center_lng"], {
        unique: false,
      });

      db.createObjectStore(provinceStoreName, { keyPath: "id" });
      const districtStore = db.createObjectStore(districtStoreName, {
        keyPath: "id",
      });

      districtStore.createIndex("mun_code", "mun_code", {
        unique: false,
      });
    };

    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // put municipalities into indexedDB
      municipalities.forEach((municipality) => {
        objectStore.put(municipality);
      });

      transaction.oncomplete = function () {
        resolve("Data indexed into IndexedDB");
      };

      transaction.onerror = function (event) {
        reject("Error indexing data into IndexedDB: " + event.target.error);
      };

      // put provinces into indexedDB
      const provinceTransaction = db.transaction(
        provinceStoreName,
        "readwrite",
      );
      const provinceObjectStore =
        provinceTransaction.objectStore(provinceStoreName);

      provinces.forEach((province) => {
        provinceObjectStore.put(province);
      });

      // put districts into indexedDB
      const districtTransaction = db.transaction(
        districtStoreName,
        "readwrite",
      );
      const districtObjectStore =
        districtTransaction.objectStore(districtStoreName);

      districts.forEach((district) => {
        districtObjectStore.put(district);
      });
    };

    dbPromise.onerror = function (event) {
      reject("Error opening IndexedDB: " + event.target.error);
    };
  });
}

// check if store exists in indexedDB
const storeExists = (storeName) => {
  return new Promise(async (resolve, reject) => {
    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);

    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const containsStore = db.objectStoreNames.contains(storeName);
      db.close();
      resolve(containsStore);
    };

    dbPromise.onerror = function (event) {
      const db = event.target.result;
      db.close();
      resolve(false);
    };
  });
};

// download full indexes from S3
export async function downloadMapData(countryCode) {
  return new Promise(async (resolve, reject) => {
    const dbs = await indexedDB.databases();
    const mapDb = dbs.find((db) => db.name === MAP_DB_NAME);

    if (mapDb && mapDb.version === CURRENT_MAP_DATA_VERSION) {
      console.log("IndexedDB mapData version up to date");

      // check if store exists and only resolve if it exists
      // otherwise we need to redownload the db
      const storeName = getStoreName(countryCode, "province");
      const storeExistsResult = await storeExists(storeName);
      if (storeExistsResult) {
        resolve();
        return;
      }
    }

    // dispatch loading state to redux store
    store.dispatch(setGlobalLoading(true));
    store.dispatch(setGlobalLoadingMessage(i18n("Updating map data...")));

    // refresh db
    if (mapDb) {
      await deleteDatabase();
    }

    try {
      const url = await Storage.get(countryCode + "-search-indexes-full.json", {
        bucket: "irealty-geojsons",
        expires: 60,
      });

      const response = await fetch(url);
      const indexes = await response.json();
      let newIndexes = [];

      for (let index of indexes) {
        newIndexes.push({
          ...index,
          center_lat: parseFloat(index.center_lat),
          center_lng: parseFloat(index.center_lng),
        });
      }

      await indexMapData(countryCode, newIndexes);
      store.dispatch(setGlobalLoading(false));
      resolve();
    } catch (error) {
      store.dispatch(setGlobalLoading(false));
      indexedDB.deleteDatabase(MAP_DB_NAME);
      reject("Error fetching map data: " + error);
    }
  });
}

// get indexed db object by id and store name
export async function getMapObjectById(storeName, id) {
  return new Promise((resolve, reject) => {
    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);
    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(id);
      request.onsuccess = function (event) {
        const object = event.target.result;
        resolve(object);
      };
      request.onerror = function (event) {
        reject("Error retrieving object from IndexedDB: " + event.target.error);
      };
    };
    dbPromise.onerror = function (event) {
      reject("Error opening IndexedDB: " + event.target.error);
    };
  });
}

// get map object by geobooundary skeleton (country, type, id)
export async function getMapObjectByGeoboundarySkeleton(geoboundary) {
  let result = null;

  if (geoboundary.type === "municipality") {
    result = await getMapObjectById(
      geoboundary.country + "-municipalities",
      geoboundary.id,
    );
  }

  if (geoboundary.type === "province") {
    result = await getMapObjectById(
      geoboundary.country + "-provinces",
      geoboundary.id,
    );
  }

  if (
    geoboundary.type === "district" ||
    geoboundary.type === "locality" ||
    geoboundary.type === "zone"
  ) {
    result = await getMapObjectById(
      geoboundary.country + "-districts",
      geoboundary.id,
    );
  }

  return result;
}

// gets all map objects by type
export function getAllMapObjectsByType(countryCode, type) {
  return new Promise((resolve, reject) => {
    let storeType = type + "s";
    if (type === "municipality") {
      storeType = "municipalities";
    }

    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);

    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const storeName = countryCode + "-" + storeType;
      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getAll();
      request.onsuccess = function (event) {
        const objects = event.target.result;
        resolve(objects);
      };
      request.onerror = function (event) {
        reject(
          "Error retrieving objects from IndexedDB: " + event.target.error,
        );
      };
    };

    dbPromise.onerror = function (event) {
      reject("Error opening IndexedDB: " + event.target.error);
    };
  });
}

// get districts by mun_code
export async function getDistrictsByMunCode(countryCode, munCode) {
  return new Promise((resolve, reject) => {
    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);
    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const districtStoreName = countryCode + "-districts";
      const transaction = db.transaction(districtStoreName, "readonly");
      const districtObjectStore = transaction.objectStore(districtStoreName);
      const index = districtObjectStore.index("mun_code");
      const request = index.getAll(munCode);
      request.onsuccess = function (event) {
        const districts = event.target.result;
        resolve(districts);
      };
      request.onerror = function (event) {
        reject(
          "Error retrieving districts from IndexedDB: " + event.target.error,
        );
      };
    };
    dbPromise.onerror = function (event) {
      reject("Error opening IndexedDB: " + event.target.error);
    };
  });
}

export function clearMapData() {
  indexedDB.deleteDatabase(MAP_DB_NAME);
}

export function deleteMapObject(id, countryCode, type) {
  let storeType = type + "s";
  if (type === "municipality") {
    storeType = "municipalities";
  }

  return new Promise((resolve, reject) => {
    const dbPromise = indexedDB.open(MAP_DB_NAME, CURRENT_MAP_DATA_VERSION);
    dbPromise.onsuccess = function (event) {
      const db = event.target.result;
      const storeName = countryCode + "-" + storeType;
      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.delete(id);
      request.onsuccess = function (event) {
        resolve("Object deleted from IndexedDB");
      };
      request.onerror = function (event) {
        reject("Error deleting object from IndexedDB: " + event.target.error);
      };
    };
    dbPromise.onerror = function (event) {
      reject("Error opening IndexedDB: " + event.target.error);
    };
  });
}

// get store name by country code and type
export function getStoreName(countryCode, type) {
  let storeType = type + "s";
  if (type === "municipality") {
    storeType = "municipalities";
  }

  if (type === "district" || type === "locality" || type === "zone") {
    storeType = "districts";
  }

  return countryCode + "-" + storeType;
}

function deleteDatabase() {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(MAP_DB_NAME);

    deleteRequest.onsuccess = function () {
      console.log("Database deleted successfully");
      resolve();
    };

    deleteRequest.onerror = function (event) {
      console.error("Error deleting database", event.target.error);
      reject(event.target.error);
    };

    deleteRequest.onblocked = function () {
      console.warn("Database deletion blocked");
    };
  });
}
