import Navbar from "components/navbar/Navbar";
import LoadingSpinner from "components/ui/LoadingSpinner/LoadingSpinner";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  setAvailability,
  setBathrooms,
  setBedrooms,
  setCatastroReference,
  setCoordinates,
  setDescription,
  setDetailedType,
  setFeatures,
  setFloorPlans,
  setIsPrivate,
  setIsPublished,
  setListingType,
  setLocation,
  setMunicipality,
  setPhotos,
  setPrice,
  setPropertyId,
  setProvince,
  setSize,
  setTitle,
  setVideos,
  setVirtualTours,
} from "../../actions/myPropertiesActions";
import { fetchPropertyRequest } from "../../api/properties";
import AvailabilityModal from "./AvailabilityModal";
import Checklist from "./Checklist";
import Description from "./Description";
import ListingAgentSettingsModal from "./ListingAgentSettingsModal";
import Location from "./Location";
import MediaUpload from "./mediaUpload/MediaUpload";
import MyPropertiesHeader from "./MyPropertiesHeader";
import PublishModal from "./PublishModal";
import SharePropertyModal from "./SharePropertyModal";
import UnpublishModal from "./UnpublishModal";
import VisibilityModal from "./VisibilityModal";

const UploadPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSharePropertyModalOpen, setIsSharePropertyModalOpen] =
    useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [isListingAgentModalOpen, setIsListingAgentModalOpen] = useState(false);

  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const catastro = useSelector((state) => state.myProperties.catastroProperty);
  const propertyType = useSelector((state) => state.myProperties.propertyType);

  dispatch(setPropertyId(propertyId));

  useEffect(() => {
    if (catastro) {
      dispatch(setTitle(`${propertyType.label} in ${catastro.municipality}`));
      dispatch(
        setCoordinates({
          latitude: catastro.latitude,
          longitude: catastro.longitude,
        }),
      );
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetchPropertyRequest(propertyId);
        const property = response.property;

        if (property) {
          await Promise.all([
            dispatch(setTitle(property.title)),
            dispatch(setPhotos(property.multimedia.images)),
            dispatch(setVideos(property.multimedia.videos)),
            dispatch(setFloorPlans(property.multimedia.plans)),
            dispatch(setVirtualTours(property.multimedia.virtualTour)),
            dispatch(setPrice(property.price)),
            dispatch(setDetailedType(property.detailedType.typology)),
            dispatch(setBedrooms(property.rooms)),
            dispatch(setBathrooms(property.bathrooms)),
            dispatch(setSize(property.size)),
            dispatch(setFeatures(property.features)),
            dispatch(setDescription(property.description || null)),
            dispatch(setLocation(property.address)),
            dispatch(setMunicipality(property.municipality)),
            dispatch(setProvince(property.province)),
            dispatch(setCatastroReference(property.refcat)),
            dispatch(setIsPublished(property.published === "1")),
            dispatch(setAvailability(property.availability)),
            dispatch(setListingType(property.saleType)),
            dispatch(setIsPrivate(property.isPrivate)),
            dispatch(
              setCoordinates({
                latitude: property.latitude,
                longitude: property.longitude,
              }),
            ),
          ]);
        }
      } catch (error) {
        console.error("Error fetching property data:", error);
        toast.error(i18n("Error loading property data"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const isDataLoaded = useSelector((state) => {
    const data = state.myProperties;
    return !!(data.title && data.propertyId);
  });

  return (
    <div className="upload-page">
      <Navbar />
      {isLoading || !isDataLoaded ? (
        <LoadingSpinner />
      ) : (
        <>
          <MyPropertiesHeader
            onClickSharePropertyButton={() => setIsSharePropertyModalOpen(true)}
          />
          <div className="max-w-vw ml-[100px] flex gap-[18px]">
            <div className="flex w-full flex-col gap-[18px]">
              <MediaUpload />
              <Description />
              <Location />
            </div>
            <div className="mr-[82px] max-w-[389px]">
              <Checklist
                onClickPublishButton={() => setIsPublishModalOpen(true)}
                onClickUnpublishButton={() => setIsUnpublishModalOpen(true)}
                onClickAvalabilityButton={() =>
                  setIsAvailabilityModalOpen(true)
                }
                onClickVisibilityButton={() => setIsVisibilityModalOpen(true)}
                onClickListingAgentButton={() =>
                  setIsListingAgentModalOpen(true)
                }
              />
            </div>
          </div>
          <SharePropertyModal
            isOpen={isSharePropertyModalOpen}
            closeModal={() => setIsSharePropertyModalOpen(false)}
          />
          <PublishModal
            isOpen={isPublishModalOpen}
            closeModal={() => setIsPublishModalOpen(false)}
          />
          <UnpublishModal
            isOpen={isUnpublishModalOpen}
            closeModal={() => setIsUnpublishModalOpen(false)}
          />
          <AvailabilityModal
            isOpen={isAvailabilityModalOpen}
            closeModal={() => setIsAvailabilityModalOpen(false)}
          />
          <VisibilityModal
            isOpen={isVisibilityModalOpen}
            closeModal={() => setIsVisibilityModalOpen(false)}
          />
          <ListingAgentSettingsModal
            isOpen={isListingAgentModalOpen}
            closeModal={() => setIsListingAgentModalOpen(false)}
          />
        </>
      )}
    </div>
  );
};

export default UploadPage;
