import { i18n } from "i18n/localisation";
import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../ui/Button/Button";
import CatastroLocation from "./CatastroLocation";

const Location = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const municipality = useSelector((state) => state.myProperties.municipality);
  const province = useSelector((state) => state.myProperties.province);
  const coordinates = useSelector((state) => state.myProperties.coordinates);

  const handleEdit = () => setIsEditMode(true);
  const handleSaveLocation = () => setIsEditMode(false);
  const image = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates?.latitude},${coordinates?.longitude}&zoom=15&size=600x400&key=AIzaSyCR06oB8bOqasfKGEU5WIOm6R2Llf9mi9o&scale=2&markers=color:red%7C${coordinates?.latitude},${coordinates?.longitude}`;

  return (
    <div className="description_container">
      <div className="description_header">
        <p>{i18n("Location")}</p>
      </div>
      {!isEditMode && (
        <>
          <div className="h-auto w-full px-[24px] py-[16px]">
            <div className="h-auto min-h-[120]">
              <p className="text-[16px] text-[#717171]">{municipality}</p>
            </div>
            <div className="h-auto min-h-[120]">
              <p className="text-[16px] text-[#717171]">{province}</p>
            </div>
          </div>
          <div className="h-[360px] w-full">
            <img src={image} alt="Property location map" />
          </div>

          <div className="description_footer">
            <div className="description_footer_cta-container">
              <div className="h-[40px] rounded-full bg-[#12B76A] px-[16px] py-[10px]">
                <p className="text-[14px] text-[#FFFFFF]">{i18n("Complete")}</p>
              </div>
              <Button onClick={handleEdit}>
                <span>{i18n("Edit")}</span>
              </Button>
            </div>
          </div>
        </>
      )}
      {isEditMode && (
        <>
          <div className="w-full py-[16px]">
            <CatastroLocation variant="page" />
          </div>
          <div className="description_footer">
            <div className="description_footer_cta-container">
              <Button variant="secondary" onClick={() => {}}>
                <span>{i18n("Cancel")}</span>
              </Button>
              <Button onClick={handleSaveLocation}>
                <span>{i18n("Save")}</span>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Location;
