import { createAction } from "@reduxjs/toolkit";

const setPhotos = createAction("myProperties/setPhotos");
const setVideos = createAction("myProperties/setVideos");
const setFloorPlans = createAction("myProperties/setFloorPlans");
const setVirtualTours = createAction("myProperties/setVirtualTours");
const setPrice = createAction("myProperties/setPrice");
const setBedrooms = createAction("myProperties/setBedrooms");
const setBathrooms = createAction("myProperties/setBathrooms");
const setSize = createAction("myProperties/setSize");
const setFeatures = createAction("myProperties/setFeatures");
const setDetailedType = createAction("myProperties/setDetailedType");
const setCatastroProperty = createAction("myProperties/setCatastroProperty");
const setListingType = createAction("myProperties/setListingType");
const setPropertyType = createAction("myProperties/setPropertyType");
const setListingAgent = createAction("myProperties/setListingAgent");
const setIsApproximateLocation = createAction(
  "myProperties/setIsApproximateLocation",
);
const setDescription = createAction("myProperties/setDescription");
const setLocation = createAction("myProperties/setLocation");
const setIsPublished = createAction("myProperties/setIsPublished");
const setTitle = createAction("myProperties/setTitle");
const clearUploadProperty = createAction("myProperties/clearUploadProperty");
const addMedia = createAction("myProperties/addMedia");
const removeMedia = createAction("myProperties/removeMedia");
const setMediaUrls = createAction("myProperties/setMediaUrls");
const setMediaByType = createAction("myProperties/setMediaByType");
const setPropertyId = createAction("myProperties/setPropertyId");
const setCoordinates = createAction("myProperties/setCoordinates");
const setMunicipality = createAction("myProperties/setMunicipality");
const setProvince = createAction("myProperties/setProvince");
const setCatastroReference = createAction("myProperties/setCatastroReference");
const setIsPrivate = createAction("myProperties/setIsPrivate");
const setAvailability = createAction("myProperties/setAvailability");

export {
  addMedia,
  clearUploadProperty,
  removeMedia,
  setAvailability,
  setBathrooms,
  setBedrooms,
  setCatastroProperty,
  setCatastroReference,
  setCoordinates,
  setDescription,
  setDetailedType,
  setFeatures,
  setFloorPlans,
  setIsApproximateLocation,
  setIsPrivate,
  setIsPublished,
  setListingAgent,
  setListingType,
  setLocation,
  setMediaByType,
  setMediaUrls,
  setMunicipality,
  setPhotos,
  setPrice,
  setPropertyId,
  setPropertyType,
  setProvince,
  setSize,
  setTitle,
  setVideos,
  setVirtualTours,
};
