import padlockCloseIcon from "assets/core/padlock_close.svg";
import padlockOpenIcon from "assets/core/padlock_open.svg";
import { i18n } from "i18n/localisation";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import rightArrow from "../../assets/core/right_chevron.svg";
import Accordion from "./Accordion";
import StatusChip from "./StatusChip";
import { ACCORDION_SECTIONS } from "./uploadPropertyPageConfig";

const Checklist = ({
  onClickPublishButton,
  onClickUnpublishButton,
  onClickAvalabilityButton,
  onClickVisibilityButton,
  onClickListingAgentButton,
}) => {
  const [openedSections, setOpenedSections] = useState(new Set());
  const uploadPropertyData = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    floorPlans: state.myProperties.floorPlans,
    virtualTours: state.myProperties.virtualTours,
    price: state.myProperties.price,
    bedrooms: state.myProperties.bedrooms,
    bathrooms: state.myProperties.bathrooms,
    size: state.myProperties.size,
    features: state.myProperties.features,
    detailedType: state.myProperties.detailedType,
    description: state.myProperties.description,
    location: state.myProperties.municipality,
    isPublished: state.myProperties.isPublished,
  }));
  const { isPublished, isPrivate, availability } = useSelector((state) => ({
    title: state.myProperties.title,
    userData: state.user.userData,
    isPublished: state.myProperties.isPublished,
    isPrivate: state.myProperties.isPrivate,
    availability: state.myProperties.availability,
  }));
  const listingAgent = useSelector((state) => state.myProperties.listingAgent);

  const isSectionCompleted = (section) => {
    const requiredSteps = section.steps.filter((step) => step.isRequired);
    const isStepsChecked = (steps) =>
      steps.every(
        (step) =>
          uploadPropertyData[step.key]?.length > 0 &&
          uploadPropertyData[step.key] !== null,
      );

    if (requiredSteps.length > 0) {
      return isStepsChecked(requiredSteps);
    }

    return isStepsChecked(section.steps);
  };

  const calculateProgress = (sections) => {
    const completedSections = sections.reduce((acc, section) => {
      return acc + isSectionCompleted(section);
    }, 0);

    return (completedSections / sections.length) * 100;
  };

  const progressPercentage = useMemo(
    () => calculateProgress(ACCORDION_SECTIONS),
    [uploadPropertyData],
  );

  const handleAccordionToggle = (sectionKey) => {
    setOpenedSections((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(sectionKey)) {
        newState.delete(sectionKey);
      } else {
        newState.add(sectionKey);
      }
      return newState;
    });
  };

  return (
    <div className="checklist">
      <div className="checklist_header">
        <h2>{i18n("Listing Checklist")}</h2>
      </div>

      <div className="checklist_progress">
        <div className="checklist_progress_percentage">
          <p>{Math.round(progressPercentage)}%</p>
        </div>
        <div className="checklist_progress_bar">
          <div
            className="checklist_progress_bar_fill"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      <div className="checklist_accordion-container">
        {ACCORDION_SECTIONS?.map(
          (section) =>
            section && (
              <Accordion
                key={section.key}
                section={section}
                isChecked={isSectionCompleted(section)}
                isOpen={openedSections.has(section.key)}
                onToggle={() => handleAccordionToggle(section.key)}
              />
            ),
        )}
      </div>

      <div className="checklist_footer">
        <h2>{i18n("Property Settings")}</h2>

        <div className="checklist_footer_settings">
          <div className="titles">
            <div>{i18n("Published status:")}</div>
            {isPublished && (
              <>
                <div>{i18n("Availability status:")}</div>
                <div>{i18n("Visibility status:")}</div>
              </>
            )}
            <div>{i18n("Listing agent:")}</div>
          </div>

          <div className="options">
            <div
              className="flex w-full justify-between"
              onClick={
                isPublished ? onClickUnpublishButton : onClickPublishButton
              }
            >
              <StatusChip status={isPublished ? "published" : "unpublished"} />
              <img src={rightArrow} alt="arrow icon" />
            </div>
            {isPublished && (
              <>
                <div
                  className="flex w-full cursor-pointer justify-between"
                  onClick={onClickAvalabilityButton}
                >
                  <StatusChip status={availability || "available"} />
                  <img src={rightArrow} alt="arrow icon" />
                </div>
                <div
                  className="flex h-8 w-full cursor-pointer items-center justify-between"
                  onClick={onClickVisibilityButton}
                >
                  <img
                    src={isPrivate ? padlockCloseIcon : padlockOpenIcon}
                    alt="padlock icon"
                  />
                  <p className="ml-3 grow">
                    {i18n(isPrivate ? "Private Listing" : "Public Listing")}
                  </p>
                  <img src={rightArrow} alt="arrow icon" />
                </div>
              </>
            )}
            <div
              className="flex h-8 w-full items-center justify-between"
              onClick={onClickListingAgentButton}
            >
              <p className="grow">
                {listingAgent?.personalDetails
                  ? `${listingAgent?.personalDetails?.firstName} ${listingAgent?.personalDetails?.lastName}`
                  : listingAgent?.email}
              </p>
              <img src={rightArrow} alt="right arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
