import { getLang } from "i18n/localisation";
import { setFetchingProperties } from "../actions/propertiesActions";
import store from "../store";
import { normalizeString } from "../utils/helpers";
import { toSnakeCase } from "../utils/properties";
import fireApiRequest from "./core";

var FETCH_PROPERTIES_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_properties";
var FETCH_PROPERTY_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_property";
var FETCH_PROPERTIES_BY_MUNICIPALITY =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_properties_by_municipality";
var FETCH_PROPERTY_BY_URL =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_property_by_url";
var PROPERTIES_BUCKET = "properties-by-municipality-dev";
var FETCH_TEAM_PORTFOLIO =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_team_portfolio";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_PROPERTIES_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_properties";
  FETCH_PROPERTY_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_property";
  FETCH_PROPERTIES_BY_MUNICIPALITY =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_properties_by_municipality";
  FETCH_PROPERTY_BY_URL =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_property_by_url";
  PROPERTIES_BUCKET = "properties-by-municipality";
  FETCH_TEAM_PORTFOLIO =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_team_portfolio";
}

/**
 * Fetches properties by criteria
 * @param {Object} property criteria object
 * @returns
 */
export async function fetchPropertiesRequest(criteria, abortController) {
  let properties = [];
  let _criteria = { ...criteria };

  // if state sale type is rent, we need to change the criteria
  if (store.getState().filters.saleType == "rent") {
    _criteria["sale_type"] = "rent";
  } else if (store.getState().filters.saleType == "sold") {
    _criteria["sale_type"] = "sold";
  } else {
    _criteria["sale_type"] = "sale";
  }

  try {
    let data = await fireApiRequest(FETCH_PROPERTIES_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(_criteria),
      signal: abortController.signal,
    });

    // return key for download if it exists
    if (data.key) {
      return {
        key: data.key,
      };
    }

    properties = data.properties;
  } catch (e) {
    console.log(e);
  }

  return properties;
}

/**
 * Fetches properties by criteria
 * @param {Object} property criteria object
 * @returns
 */
export async function fetchPropertiesByMunicipalityRequest(municipality) {
  store.dispatch(setFetchingProperties(true));
  let properties = [];

  try {
    // fire update but we won't wait for it
    fireApiRequest(FETCH_PROPERTIES_BY_MUNICIPALITY, {
      method: "POST",
      body: JSON.stringify({ municipality }),
    });

    let bucketKey = toSnakeCase(normalizeString(municipality)) + ".json";
    let response = await fetch(
      `https://${PROPERTIES_BUCKET}.s3.eu-west-2.amazonaws.com/${bucketKey}`,
    );
    properties = await response.json();

    if (properties.length > 1000) {
      properties = properties.slice(0, 1000);
    }
  } catch (e) {
    console.log(e);
  }

  store.dispatch(setFetchingProperties(false));
  return properties;
}

// prevent doubling up of fetch property request
var fetchingProperty = false;

/**
 * Fetches property by id
 * @param {String} property id
 * @returns {Object} result property with duplicates object
 */
export async function fetchPropertyRequest(id, saleType = "sale") {
  if (fetchingProperty) {
    return {
      property: null,
    };
  }

  fetchingProperty = true;
  let result = {
    property: null,
    duplicates: [],
  };

  try {
    let data = await fireApiRequest(FETCH_PROPERTY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        id,
        sale_type: saleType,
        lang: getLang().code, // translate descriptions if necessary
      }),
    });

    result.property = data.property;
    result.duplicates = data.duplicates;
    result.is_deleted_property = data.is_deleted_property;
  } catch (e) {
    console.log(e);
  }

  fetchingProperty = false;
  return result;
}

/**
 * Fetches property by url
 * @param {String} url
 * @returns {Object} property object
 */
export async function fetchPropertyByUrlRequest(url) {
  let property = null;

  try {
    let data = await fireApiRequest(FETCH_PROPERTY_BY_URL, {
      method: "POST",
      body: JSON.stringify({ url }),
    });

    property = data.property;
  } catch (e) {
    console.log(e);
  }

  return property;
}

export async function fetchTeamPortfolioRequest() {
  let properties = null;

  try {
    let data = await fireApiRequest(FETCH_TEAM_PORTFOLIO, {
      method: "POST",
    });

    properties = data.properties;
  } catch (e) {
    console.log(e);
  }

  return properties;
}

export { FETCH_PROPERTIES_ENDPOINT, FETCH_PROPERTY_ENDPOINT };
