import { savePropertyRequest } from "api/crm";
import plusIcon from "assets/core/plus.svg";
import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import PUBLIC_MEDIA_BASE_URL, { getMimeType, uploadFile } from "utils/file";
import { v4 as uuidv4 } from "uuid";
import { addMedia, setMediaByType } from "../../../actions/myPropertiesActions";
import Button from "../../ui/Button/Button";
import IconComponent from "../IconComponent";
import {
  ICON_CONFIG,
  MEDIA_TABS,
  NO_MEDIA_CONTAINER,
} from "../uploadPropertyPageConfig";
import { GridMediaTiles } from "./GridMediaTiles";
import MediaEmptyTile from "./MediaEmptyTile";
import { Layout } from "./MediaTile";
import { generateVideoThumbnail, getAcceptedFileTypes } from "./utils";

const MediaUpload = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(MEDIA_TABS[0]);
  const { propertyId } = useParams();
  const fileInputRef = useRef(null);

  const media = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    floorPlans: state.myProperties.floorPlans,
    virtualTours: state.myProperties.virtualTours,
  }));

  const hasMedia = Object.values(media).some((tab) => tab && tab.length > 0);

  const triggerFileSelect = () => fileInputRef.current?.click();

  const handleFileSelect = async (files) => {
    try {
      const validFiles = files.filter((file) => {
        const maxSize =
          selectedTab.key === "videos" ? 100 * 1024 * 1024 : 32 * 1024 * 1024;
        return file.size <= maxSize;
      });

      if (validFiles.length === 0) {
        toast.error(i18n("Files exceed maximum size limit"));
        return;
      }

      const fileURLs = await Promise.all(
        validFiles.map(async (file) => {
          const localUrl = URL.createObjectURL(file);
          const baseMedia = {
            id: uuidv4(),
            localUrl,
            file,
          };

          if (selectedTab.key === "videos") {
            const thumbnail = await generateVideoThumbnail(file);
            return {
              ...baseMedia,
              thumbnail,
              type: "video",
            };
          }

          return {
            ...baseMedia,
            type: "image",
          };
        }),
      );

      dispatch(addMedia({ type: selectedTab.key, media: fileURLs }));
    } catch (error) {
      toast.error(i18n("Error uploading files: ") + error.message);
      console.error("Upload failed:", error);
    }
  };

  const handleSaveMedia = async () => {
    try {
      const mediaTypes = ["photos", "videos", "floorPlans", "virtualTours"];
      const updates = {};

      for (const type of mediaTypes) {
        if (!media[type]?.length) continue;

        const unsavedMedia = media[type].filter(
          (item) => !item.url && item.file.name,
        );

        if (unsavedMedia.length === 0) continue;

        const uploadedItems = await Promise.all(
          unsavedMedia.map(async (item) => {
            if (item.thumbnail) {
              const thumbnailS3 = await uploadFile(
                item.thumbnail.file,
                `properties/${propertyId}/${type}/${item.id}.png`,
                getMimeType("png"),
              );

              const path = `properties/${propertyId}/${type}/${item.id}.mp4`;
              const videoS3 = await uploadFile(
                item.file,
                path,
                getMimeType("mp4"),
              );

              return {
                ...item,
                url: {
                  thumbnail: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}/${thumbnailS3.key}`,
                  video: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}/${videoS3.key}`,
                },
              };
            }

            const extension = item.file.name.split(".").pop();
            const path = `properties/${propertyId}/${type}/${item.id}.${extension}`;
            const result = await uploadFile(
              item.file,
              path,
              getMimeType(extension),
            );

            return {
              ...item,
              url: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}${result.key}`,
            };
          }),
        );

        updates[type] = [
          ...media[type].filter((item) => item.url),
          ...uploadedItems,
        ];
      }

      // Update Redux store with new media arrays
      Object.entries(updates).forEach(([type, items]) => {
        dispatch(setMediaByType({ type, items }));
      });

      const buildObjectToSave = () => {
        const databaseKeysDictionary = {
          photos: "images",
          videos: "videos",
          floorPlans: "plans",
          virtualTours: "virtualTour",
        };
        const objectToSave = {
          multimedia: { images: [], videos: [], plans: [], virtualTour: [] },
        };

        mediaTypes.forEach((type) => {
          const items = updates[type] ? updates[type] : media[type];
          objectToSave.multimedia[databaseKeysDictionary[type]] = items.map(
            (item) => ({
              ...item,
              url: item.url,
            }),
          );
        });

        const getPropertyThumbnail = () => {
          if (objectToSave.multimedia.images.length > 0) {
            return objectToSave.multimedia.images[0].url;
          }

          if (objectToSave.multimedia.videos.length > 0) {
            return objectToSave.multimedia.videos[0].thumbnail;
          }

          if (objectToSave.multimedia.plans.length > 0) {
            return objectToSave.multimedia.plans[0].url;
          }

          return;
        };

        return { objectToSave, thumbnail: getPropertyThumbnail() };
      };

      // Save property with media
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: buildObjectToSave(),
      });
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error(i18n("Error saving media"));
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup object URLs when component unmounts
      Object.values(media)?.forEach((mediaArray) => {
        mediaArray?.forEach((item) => {
          if (item.url) URL.revokeObjectURL(item.url);
          if (item.thumbnail) URL.revokeObjectURL(item.thumbnail);
        });
      });
    };
  }, [media]);

  return (
    <div className={`${"media-upload_container"}`}>
      <div className="media-upload_header">
        <p>{i18n("Property Media")}</p>
        {!!media[selectedTab.key]?.length && (
          <div
            className="flex cursor-pointer gap-[2px]"
            onClick={triggerFileSelect}
          >
            <img src={plusIcon} alt="add media" />
            <p>{NO_MEDIA_CONTAINER[selectedTab?.key]?.label}</p>
          </div>
        )}
      </div>
      <div className={"media-upload_add-media-container"}>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept={getAcceptedFileTypes(selectedTab)}
          multiple
          onChange={(e) => {
            handleFileSelect(Array.from(e.target.files));
            e.target.value = "";
          }}
        />
        {media[selectedTab.key] && media[selectedTab.key]?.length ? (
          <GridMediaTiles
            layout={Layout.Grid}
            images={media[selectedTab.key]}
            tab={selectedTab}
            onFileSelect={triggerFileSelect}
          />
        ) : (
          <MediaEmptyTile
            tab={selectedTab || MEDIA_TABS[0]}
            size="standard"
            onFileSelect={triggerFileSelect}
          />
        )}
      </div>
      <div className={`${"media-upload_footer"}`}>
        <div className={"media-upload_footer_tabs"}>
          {MEDIA_TABS.map((tab) => (
            <div
              className={`${"media-upload_footer_tab"} 
              ${tab === selectedTab ? "border-t-0" : "border-t-[1px] bg-[#FAFBFC]"} 
              ${tab === MEDIA_TABS[0] ? "rounded-bl-2xl border-l-0" : "rounded-none border-l-[1px]"}
              ${tab === MEDIA_TABS[3] ? "rounded-br-2xl" : "rounded-none"}`}
              onClick={() => setSelectedTab(tab)}
            >
              <div className="h-[20px] w-[20px]">
                <IconComponent
                  icon={tab.icon}
                  color={tab === selectedTab ? "#222222" : "#717171"}
                  parameter={ICON_CONFIG[tab.key].parameter}
                />
              </div>
              <p
                className={
                  tab === selectedTab
                    ? "font-medium text-[#222222]"
                    : "font-medium text-[#717171]"
                }
              >
                {`${tab.label} (${media[tab.key]?.length || 0})`}
              </p>
            </div>
          ))}
        </div>
        {hasMedia && (
          <div className={`${"media-upload_footer_cta-container"}`}>
            <Button variant="secondary">
              <span>{i18n("Cancel")}</span>
            </Button>
            <Button onClick={() => handleSaveMedia()}>
              <span>{i18n("Save")}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaUpload;
