import { setCmaProperty } from "actions/cmaActions";
import { setHoveredArea, toggleSelectedArea } from "actions/mapActions";
import { setSelectedPlot } from "actions/plotsActions";
import {
  incrementNumberOfPolygonsFetched,
  setDrawnPolygons,
} from "actions/polygonActions";
import { fetchPlotsByBounds, getPlotByRefCat } from "api/plots";
import {
  MAX_ALLOWED_AREAS,
  ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_PLOTS,
} from "config/constants";
import { polygonColours } from "config/map";
import { CURRENT_MAP_DATA_VERSION, mapDbExists } from "db/mapStore";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { filterProperties } from "lib/filter/filters";
import { toast } from "sonner";
import {
  deleteProperties,
  setFilteredProperties,
  setProperties,
} from "../actions/propertiesActions";
import store from "../store";
import {
  debouncedMapPanTo,
  extractNumbersFromString,
  toCapitalCase,
} from "./helpers";
import {
  googlePolygonToGeometryCoordinates,
  serializePolygon,
} from "./polygon";
import { fetchPropertiesByPolygon } from "./properties";
/**
 * converts an object returned from the geoboundary request to geojson
 */
export function convertGeoboundaryResponseToGeoJson(response) {
  let geojson = {};
  geojson.type = "FeatureCollection";
  let features = [];

  for (let boundary of response.boundaries) {
    let feature = {};
    feature.geometry = boundary.geometry;
    feature.type = "Feature";
    let boundaryCopy = Object.assign({}, boundary);
    delete boundaryCopy.geometry;
    feature.properties = boundaryCopy;
    features.push(feature);
  }

  geojson.features = features;
  return geojson;
}

// internal callback to assign properties polygon ids and to dispatch properties to store
function internalCreatePolygonSuccessCallback(
  polygon,
  polygonId,
  properties,
  resolve,
) {
  let propertiesAssociatedWithPolygon = properties.map((p) => {
    let property = Object.assign({}, p);
    property.polygonId = polygonId;
    return property;
  });

  // only add properties into the state if the polygon hasnt been deleted
  // before the request has had a chance to come back
  if (
    store
      .getState()
      .polygon.drawnPolygons.map((p) => p.irealtyId)
      .includes(polygonId)
  ) {
    // now set filtered properties as new properties have been consumed
    // into the state
    let properties = store.getState().property.properties;
    properties = [].concat(properties, propertiesAssociatedWithPolygon);
    store.dispatch(setProperties(properties));
    store.dispatch(setFilteredProperties(filterProperties(properties)));
    resolve(propertiesAssociatedWithPolygon);
  }
}

// event handler for when polygons are drawn on the map
export function onCreatePolygon(polygon, polygonId) {
  return new Promise((resolve, reject) => {
    fetchPropertiesByPolygon(polygon, polygonId, (properties) => {
      internalCreatePolygonSuccessCallback(
        polygon,
        polygonId,
        properties,
        resolve,
      );
    });
  });
}

// event handler for when polygons are deleted from the map
export function onDeletePolygon(polygon, polygonId) {
  let drawnPolygons = store.getState().polygon.drawnPolygons;
  delete window.polygonAbortControllers[polygonId];
  let properties = store.getState().property.properties;

  // if we are on the last polygon, simply delete all properties
  if (drawnPolygons.length == 1) {
    store.dispatch(setProperties([]));
    store.dispatch(setFilteredProperties([]));
    store.dispatch(incrementNumberOfPolygonsFetched(0));
    return properties;
  }

  // filter out properties that are tied to the deleted polygon
  let propertiesInPolygon = properties.filter(
    (property) => polygonId == property.polygonId && !property.isCmaProperty,
  );

  store.dispatch(deleteProperties(propertiesInPolygon));
  return propertiesInPolygon;
}

/**
 * formats an array of properties to geojson points
 */
export function formatPropertiesToGeoJsonPoints(properties) {
  return properties.map((property) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [property.longitude, property.latitude],
    },
    properties: {
      property,
      cluster: false,
    },
  }));
}

/**
 * pan a google maps instance by pixels instead of latlng
 */
export function panMapByPixels(map, deltaX, deltaY) {
  // Get the current map bounds
  const bounds = map.getBounds();

  // Convert pixel distances to LatLng differences
  const latLngDelta = {
    lat:
      deltaY /
      (map.getDiv().offsetHeight /
        (bounds.getNorthEast().lat() - bounds.getSouthWest().lat())),
    lng:
      deltaX /
      (map.getDiv().offsetWidth /
        (bounds.getNorthEast().lng() - bounds.getSouthWest().lng())),
  };

  // Get the center position
  const center = map.getCenter();

  // Calculate the new center
  const newCenter = {
    lat: center.lat() + latLngDelta.lat,
    lng: center.lng() + latLngDelta.lng,
  };

  // Pan to the new center
  map.panTo(newCenter);
  debouncedMapPanTo(map, newCenter);
}

// calculates bounding rect area in km2
export function calculateRectangleArea(rectangle) {
  const { minLat, maxLat, minLong, maxLong } = rectangle;
  const width =
    Math.abs(maxLong - minLong) *
    111.32 *
    Math.cos(((minLat + maxLat) * Math.PI) / 360); // Approximating 1 degree latitude ~ 111.32 km
  const height = Math.abs(maxLat - minLat) * 110.574; // 1 degree longitude ~ 110.574 km
  return width * height;
}

// splits geospatial rect into smaller squared bounds
export function subdivideBounds(rectangle, latDegrees, longDegrees) {
  const { minLat, maxLat, minLong, maxLong } = rectangle;
  const squares = [];

  // Calculate the number of squares in the horizontal and vertical directions
  const horizontalSquares = Math.ceil((maxLong - minLong) / longDegrees);
  const verticalSquares = Math.ceil((maxLat - minLat) / latDegrees);

  // Calculate the size of each square in degrees
  const squareWidth = (maxLong - minLong) / horizontalSquares;
  const squareHeight = (maxLat - minLat) / verticalSquares;

  // Iterate over the horizontal and vertical directions to create squares
  for (let i = 0; i < horizontalSquares; i++) {
    const squareMinLong = minLong + i * squareWidth;

    for (let j = 0; j < verticalSquares; j++) {
      const squareMinLat = minLat + j * squareHeight;

      squares.push({
        minLat: squareMinLat,
        maxLat: squareMinLat + squareHeight,
        minLong: squareMinLong,
        maxLong: squareMinLong + squareWidth,
      });
    }
  }

  return squares;
}

export function getGlobalMapInstance() {
  return window.googleMapsInstance;
}

// Plot map management functions
window.drawnPlots = [];

// clear all drawn plots from global map instance
export function clearDrawnPlots() {
  for (let plot of window.drawnPlots) {
    plot.setMap(null);
  }

  window.drawnPlots = [];
}

// clear all drawn plots from global map instance apart from the selected plot
export function clearAllButSelectedPlot() {
  let storeState = store.getState();
  const selectedPlot = storeState.plots.selectedPlot;

  if (selectedPlot == null) {
    clearDrawnPlots();
    return;
  }

  for (let plot of window.drawnPlots) {
    if (plot.plotId != selectedPlot.id) {
      plot.setMap(null);
    }
  }

  window.drawnPlots = window.drawnPlots.filter(
    (p) => p.plotId == selectedPlot.id,
  );
}

// dispatch selected plot to store and focus on it
function onPlotClick(plot, polygon) {
  const { showPlots, selectedPlot } = store.getState().plots;

  // deselect plot if it is already selected
  if (selectedPlot && selectedPlot.id == plot.id) {
    store.dispatch(setSelectedPlot(null));
    window.drawnPlots
      .find((p) => p.plotId == plot.id)
      .setOptions({
        fillColor: "#000000",
        fillOpacity: 0.25,
        strokeColor: polygonColours.primary,
        strokeOpacity: 1,
        strokeWeight: 3,
      });
    return;
  }

  store.dispatch(setSelectedPlot(plot));

  // reset all other plots to unfocused
  if (showPlots) {
    for (let plot of window.drawnPlots) {
      if (plot.plotType == "U") {
        plot.setOptions(
          getUrbanPlotPolygonOptions(plot.getPath().getArray(), true),
        );
      } else {
        plot.setOptions(
          getRuralPlotPolygonOptions(plot.getPath().getArray(), true),
        );
      }
    }
  } else {
    for (let plot of window.drawnPlots) {
      plot.setOptions({
        fillColor: "#000000",
        fillOpacity: 0,
        strokeColor: polygonColours.primary,
        strokeOpacity: 0,
        strokeWeight: 3,
      });
    }
  }

  // focus selected plot
  polygon.setOptions({
    fillColor: polygonColours.hover,
    fillOpacity: 0.5,
    strokeColor: polygonColours.hover,
    strokeOpacity: 1,
    strokeWeight: 4,
    zIndex: 3,
  });
}

function getUrbanPlotPolygonOptions(polygonPath, showPlots) {
  return {
    paths: polygonPath,
    strokeColor: polygonColours.primary,
    strokeOpacity: showPlots ? 1 : 0,
    strokeWeight: 1.75,
    fillColor: "#000000",
    fillOpacity: showPlots ? 0.25 : 0,
    draggable: false,
    editable: false,
    zIndex: 2,
  };
}

function getRuralPlotPolygonOptions(polygonPath, showPlots) {
  return {
    paths: polygonPath,
    strokeColor: polygonColours.primary,
    strokeOpacity: showPlots ? 1 : 0,
    strokeWeight: 1.75,
    fillColor: "#000000",
    fillOpacity: 0,
    draggable: false,
    editable: false,
    zIndex: 1,
  };
}

// draw a single selected plot onto the global map instance
export function drawSingleSelectedPlot(plot) {
  const mapInstance = getGlobalMapInstance();
  const polygonPath = plot.geometry.coordinates[0].map((coord) => {
    return new window.google.maps.LatLng(
      parseFloat(coord[1]),
      parseFloat(coord[0]),
    );
  });

  let polygonOptions = getRuralPlotPolygonOptions(polygonPath, true);
  if (plot.type == "U") {
    polygonOptions = getUrbanPlotPolygonOptions(polygonPath, true);
  }

  let polygon = new window.google.maps.Polygon(polygonOptions);
  polygon.addListener("click", function (event) {
    onPlotClick(plot, polygon);
  });

  polygon.setMap(mapInstance);
  polygon.isPlot = true;
  polygon.plotId = plot.id;
  polygon.plotType = plot.type;
  window.drawnPlots.push(polygon);
  onPlotClick(plot, polygon);
}

// redraw all plots passed into func onto global map instance
export function drawPlots(plots) {
  // clear all drawn plots bar the selected one
  clearAllButSelectedPlot();
  const storeState = store.getState();
  const { selectedPlot, showPlots } = storeState.plots;
  const mapInstance = getGlobalMapInstance();

  // dont draw plots if zoom level is too low
  if (mapInstance.zoom < ZOOM_LEVEL_TO_SHOW_PLOTS) {
    return;
  }

  for (let plot of plots) {
    // dont redraw selected plot
    if (selectedPlot && selectedPlot.id == plot.id) {
      continue;
    }

    let polygonPath = [];

    for (const coord of plot.geometry.coordinates[0]) {
      const latLng = new window.google.maps.LatLng(
        parseFloat(coord[1]),
        parseFloat(coord[0]),
      );
      polygonPath.push(latLng);
    }

    let polygonOptions = getRuralPlotPolygonOptions(polygonPath, showPlots);
    if (plot.type == "U") {
      polygonOptions = getUrbanPlotPolygonOptions(polygonPath, showPlots);
    }

    let polygon = new window.google.maps.Polygon(polygonOptions);
    polygon.addListener("click", function (event) {
      onPlotClick(plot, polygon);
    });

    polygon.setMap(mapInstance);
    polygon.isPlot = true;
    polygon.plotId = plot.id;
    polygon.plotType = plot.type;
    window.drawnPlots.push(polygon);
  }
}

export function getMapBounds() {
  const mapInstance = getGlobalMapInstance();
  const mapBounds = mapInstance.getBounds();
  const minLat = mapBounds.getSouthWest().lat();
  const maxLat = mapBounds.getNorthEast().lat();
  const minLon = mapBounds.getSouthWest().lng();
  const maxLon = mapBounds.getNorthEast().lng();

  return {
    min_lat: parseFloat(minLat.toFixed(6)),
    max_lat: parseFloat(maxLat.toFixed(6)),
    min_lng: parseFloat(minLon.toFixed(6)),
    max_lng: parseFloat(maxLon.toFixed(6)),
  };
}

// prevent doubling of toasts
var plotToastId = null;

// fetch plots within the bounds of the current map instance
export async function fetchPlotsByMapBounds() {
  const storeState = store.getState();
  if (!storeState.plots.canDrawPlots) {
    return;
  }

  const bounds = getMapBounds();
  let promise = fetchPlotsByBounds(bounds);

  // show toast to indicate loading
  if (plotToastId !== null) {
    toast.dismiss(plotToastId);
  }

  plotToastId = toast.promise(promise, {
    loading: i18n("Loading plots..."),
    success: i18n("Plots loaded"),
    error: i18n("Failed to load plots"),
    duration: 500,
  });

  let plots = await promise;
  if (plots && plots.length > 0) {
    drawPlots(plots);
  }
}

export function hidePlots(selectedPlot) {
  for (let plot of window.drawnPlots) {
    if (selectedPlot && plot.plotId == selectedPlot.id) {
      continue;
    }

    plot.setOptions({
      fillOpacity: 0,
      strokeOpacity: 0,
    });
  }
}

export function showPlots() {
  for (let plot of window.drawnPlots) {
    plot.setOptions({
      fillOpacity: plot.plotType == "U" ? 0.25 : 0,
      strokeOpacity: 1,
    });
  }
}

export function clearAllSelectedMunicipalityPolygons() {
  for (let polygon of window.drawnPolygons) {
    if (polygon.metadata && polygon.metadata.type == "municipality") {
      polygon.setMap(null);
    }
  }

  window.drawnPolygons = window.drawnPolygons.filter(
    (p) => p.metadata?.type != "municipality",
  );

  store.dispatch(
    setDrawnPolygons(
      window.drawnPolygons.map((polygon) => serializePolygon(polygon)),
    ),
  );
}

// for multi municipality drawing on map
window.drawnGeographicPolygons = [];

function latLngToPixel(latLng) {
  const map = getGlobalMapInstance();
  const scale = Math.pow(2, map.getZoom());
  const proj = map.getProjection();
  const bounds = map.getBounds();

  const nw = proj.fromLatLngToPoint(bounds.getNorthEast());
  const point = proj.fromLatLngToPoint(latLng);

  return new window.google.maps.Point(
    (point.x - nw.x) * scale,
    (point.y - nw.y) * scale,
  );
}

function moveHoverInfo(latLng) {
  const hoverInfo = document.getElementById("hoverInfo");

  if (!hoverInfo) {
    return;
  }

  hoverInfo.style.right =
    Math.abs(latLngToPixel(latLng).x) - hoverInfo.offsetWidth / 2 + "px";
  hoverInfo.style.top = latLngToPixel(latLng).y + 40 + "px";
}

// highlight municipality/province polygons on mouse over
function onGeographicPolygonMouseOver(polygon, event) {
  if (window.selectedActiveGeographicPolygonId == polygon.area.id) {
    return;
  }

  if (!store.getState().map.hideBounds) {
    polygon.setOptions({
      strokeColor: polygonColours.hover,
      strokeOpacity: 1,
      fillColor: polygonColours.hover,
      fillOpacity: 0.5,
      zIndex: 2,
      strokeWeight: 3,
    });
  }

  store.dispatch(setHoveredArea(polygon.area));
}

// unhighlight municipality/province polygons on mouse out
function onGeographicPolygonMouseOut(polygon, event) {
  // if the polygon is selected, don't continue
  if (window.selectedActiveGeographicPolygonId == polygon.area.id) {
    return;
  }

  if (!store.getState().map.hideBounds) {
    polygon.setOptions({
      strokeColor: polygonColours.primary,
      strokeOpacity: polygon.area.type == "municipality" ? 0.25 : 1,
      fillColor: polygonColours.primary,
      fillOpacity: polygon.area.type == "province" ? 0.1 : 0,
      zIndex: 0,
      strokeWeight: 1.75,
    });

    // if we have a selected active polygon, turn the lights down on the rest
    if (window.selectedActiveGeographicPolygonId) {
      polygon.setOptions({
        fillColor: "#000",
        fillOpacity: 0.4,
      });
    }
  }

  store.dispatch(setHoveredArea(null));
}

// redraw all selectable areas
export function drawGeographicPolygons(areas, onClickCallback) {
  const storeState = store.getState();
  const hideBounds = storeState.map.hideBounds;
  const mapInstance = getGlobalMapInstance();

  for (let area of areas) {
    function _drawPolygon(coordinates) {
      let polygonPath = [];

      for (const coord of coordinates) {
        const latLng = new window.google.maps.LatLng(
          parseFloat(coord[1]),
          parseFloat(coord[0]),
        );
        polygonPath.push(latLng);
      }

      let polygon = new window.google.maps.Polygon({
        paths: polygonPath,
        strokeColor: polygonColours.primary,
        strokeOpacity: area.type == "municipality" ? 0.25 : 1,
        fillColor: polygonColours.primary,
        fillOpacity: area.type == "province" ? 0.1 : 0,
        draggable: false,
        editable: false,
        strokeWeight: 1.75,
      });

      // if we have a selected active polygon, turn the lights down on the rest
      if (window.selectedActiveGeographicPolygonId) {
        polygon.setOptions({
          fillColor: "#000",
          fillOpacity: 0.4,
        });
      }

      // retain active polygon state
      if (window.selectedActiveGeographicPolygonId == area.id) {
        polygon.setOptions({
          strokeColor: polygonColours.active,
          strokeOpacity: 1,
          fillOpacity: 0,
          strokeWeight: 3,
          zIndex: 1,
        });
      }

      if (hideBounds) {
        polygon.setOptions({
          fillOpacity: 0,
          strokeOpacity: 0,
        });
      }

      polygon.addListener("mouseover", function (event) {
        onGeographicPolygonMouseOver(polygon, event);
      });

      polygon.addListener("mouseout", function (event) {
        onGeographicPolygonMouseOut(polygon, event);
      });

      polygon.addListener("mousemove", function (event) {
        moveHoverInfo(event.latLng);
      });

      polygon.addListener("click", function (event) {
        store.dispatch(setHoveredArea(null));

        if (onClickCallback) {
          if (process.env.REACT_APP_NODE_ENV !== "production") {
            console.log(
              JSON.stringify({
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Polygon",
                      coordinates: [
                        googlePolygonToGeometryCoordinates(polygon),
                      ],
                    },
                  },
                ],
              }),
            );
          }

          onClickCallback(area);

          // send municipality click event
          if (area.type == "municipality") {
            sendAnalyticsEvent("Municipality Click", {
              municipality: area.name,
            });
          }

          return;
        }

        const map = getGlobalMapInstance();
        map.setZoom(ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES + 1);

        const center = {
          lat: parseFloat(area.center_lat),
          lng: parseFloat(area.center_lng),
        };

        map.setCenter(center);
      });

      polygon.setMap(mapInstance);
      polygon.area = area;
      polygon.irealtyId = area.id;
      window.drawnGeographicPolygons.push(polygon);
    }

    if (area.geometry.type == "MultiPolygon") {
      for (let coordinates of area.geometry.coordinates) {
        _drawPolygon(coordinates[0]);
      }
    } else {
      _drawPolygon(area.geometry.coordinates[0]);
    }
  }
}

// clears the geographically drawn areas
export function clearAllDrawnGeographicPolygons() {
  for (let polygon of window.drawnGeographicPolygons) {
    polygon.setMap(null);
  }

  window.drawnGeographicPolygons = [];
}

function getLocationsInRange(db, bounds) {
  return new Promise((resolve, reject) => {
    const storeName = "es-municipalities";
    let transaction = db.transaction([storeName], "readonly");
    let objectStore = transaction.objectStore(storeName);
    let index = objectStore.index("lat_lng");

    let lowerBound = [bounds.min_lat, bounds.min_lng];
    let upperBound = [bounds.max_lat, bounds.max_lng];

    let keyRange = IDBKeyRange.bound(lowerBound, upperBound);
    let request = index.openCursor(keyRange);

    let results = [];

    request.onsuccess = function (event) {
      let cursor = event.target.result;
      if (cursor) {
        results.push(cursor.value);
        cursor.continue();
      } else {
        resolve(results);
      }
    };

    request.onerror = function (event) {
      reject(event.target.errorCode);
    };
  });
}

// gets locations in range from indexed db
export function getLocationsInRangeFromDb(bounds) {
  return new Promise((resolve, reject) => {
    const dbPromise = indexedDB.open("mapData", CURRENT_MAP_DATA_VERSION);
    dbPromise.onsuccess = async function (event) {
      const db = event.target.result;
      const municipalities = await getLocationsInRange(db, bounds);
      resolve(municipalities);
      db.close();
    };

    dbPromise.onerror = function (event) {
      reject(event.target.errorCode);
    };
  });
}

// fetch municipalities within the bounds of the current map instance
export async function fetchMunicipalitiesByMapBounds(onClickCallback) {
  const existingDb = await mapDbExists();
  if (!existingDb) {
    return;
  }

  if (existingDb.version < CURRENT_MAP_DATA_VERSION) {
    return;
  }

  const dbPromise = indexedDB.open("mapData", CURRENT_MAP_DATA_VERSION);
  let bounds = getMapBounds();

  // extend bounds to ensure we get all municipalities
  bounds.min_lat = bounds.min_lat - 0.2;
  bounds.min_lng = bounds.min_lng - 0.2;
  bounds.max_lat = bounds.max_lat + 0.2;
  bounds.max_lng = bounds.max_lng + 0.2;

  dbPromise.onsuccess = async function (event) {
    const db = event.target.result;
    const municipalities = await getLocationsInRange(db, bounds);
    clearAllDrawnGeographicPolygons();

    if (!cannotDrawGeographicPolygons()) {
      drawGeographicPolygons(municipalities, onClickCallback);
    }

    db.close();
  };

  dbPromise.onerror = function (event) {
    event.target.result.close();
  };
}

export function clearAllDrawnMunicipalities() {
  for (let polygon of window.drawnGeographicPolygons) {
    if (polygon.area?.type == "municipality") {
      polygon.setMap(null);
    }
  }

  window.drawnGeographicPolygons = window.drawnGeographicPolygons.filter(
    (p) => p.area?.type != "municipality",
  );
}

export function clearAllDrawnProvinces() {
  for (let polygon of window.drawnGeographicPolygons) {
    if (polygon.area?.type == "province") {
      polygon.setMap(null);
    }
  }

  window.drawnGeographicPolygons = window.drawnGeographicPolygons.filter(
    (p) => p.area?.type != "province",
  );
}

export function cannotDrawGeographicPolygons() {
  const storeState = store.getState();

  return (
    window.location.pathname.includes("/valuation") ||
    window.drawnPolygons.length > 0 ||
    !storeState.map.canDrawGeographicPolygons
  );
}

// fetch municipalities within the bounds of the current map instance
export function shouldDrawGeographicBounds(onClickCallback) {
  const map = getGlobalMapInstance();

  if (!map) {
    return;
  }

  if (cannotDrawGeographicPolygons()) {
    return clearAllDrawnGeographicPolygons();
  }

  if (
    map.zoom > ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES &&
    map.zoom <= ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES
  ) {
    fetchMunicipalitiesByMapBounds(onClickCallback);
  } else if (map.zoom <= ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES) {
    // no need to draw provinces if we already have them drawn
    if (
      !window.drawnGeographicPolygons.some((p) => p.area?.type == "province")
    ) {
      drawProvinces();
    }
  } else {
    clearAllDrawnMunicipalities();
  }
}

export function hideGeographicBounds() {
  for (let area of window.drawnGeographicPolygons) {
    area.setOptions({
      fillOpacity: 0,
      strokeOpacity: 0,
      clickable: false,
    });
  }
}

export function showGeographicBounds() {
  for (let area of window.drawnGeographicPolygons) {
    area.setOptions({
      fillOpacity: area.area.type == "municipality" ? 0 : 0.1,
      strokeOpacity: area.area.type == "municipality" ? 0.25 : 1,
      clickable: true,
    });
  }
}

export function getDrawnProvinces() {
  return window.drawnGeographicPolygons.filter(
    (p) => p.area?.type == "province",
  );
}

// fetch municipalities within the bounds of the current map instance
export async function drawProvinces(onClickCallback) {
  const existingDb = await mapDbExists();
  if (!existingDb) {
    return;
  }

  if (existingDb.version != CURRENT_MAP_DATA_VERSION) {
    return;
  }

  const dbPromise = indexedDB.open("mapData", CURRENT_MAP_DATA_VERSION);

  dbPromise.onsuccess = async function (event) {
    const db = event.target.result;
    const storeName = "es-provinces";

    if (!db.objectStoreNames.contains(storeName)) {
      db.close();
      return;
    }

    let transaction = db.transaction([storeName], "readonly");
    let objectStore = transaction.objectStore(storeName);
    const request = await objectStore.getAll();

    request.onsuccess = function (event) {
      const provinces = event.target.result;

      // prevent uneccessary redraws
      if (
        getGlobalMapInstance().zoom < ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES &&
        getDrawnProvinces().length == 0
      ) {
        clearAllDrawnMunicipalities();
        drawGeographicPolygons(provinces);
      }

      db.close();
    };

    request.onerror = function (event) {
      db.close();
    };
  };

  dbPromise.onerror = function (event) {
    event.target.result.close();
  };
}

// MARK: functions tied to the AreaSelectionPanel workflow
window.drawnHoverDistricts = [];
window.drawnSelectedDistricts = [];

// clear hoverable districts from the map
export function clearDrawnHoverDistricts() {
  for (let polygon of window.drawnHoverDistricts) {
    polygon.setMap(null);
  }

  window.drawnHoverDistricts = [];
}

// clear selected districts from the map
export function clearDrawnSelectedDistricts() {
  for (let polygon of window.drawnSelectedDistricts) {
    polygon.setMap(null);
  }

  window.drawnSelectedDistricts = [];
}

// hide hoverable districts from the map
export function hideHoverDistricts() {
  for (let polygon of window.drawnHoverDistricts) {
    polygon.setOptions({
      strokeOpacity: 0,
      fillOpacity: 0,
    });
  }
}

// show hoverable district on hover
export function onHoverOverDistrict(district) {
  for (let polygon of window.drawnHoverDistricts) {
    if (polygon.district.id == window.selectedActiveGeographicPolygonId) {
      continue;
    }

    if (polygon.district.id == district.id) {
      polygon.setOptions({
        strokeOpacity: 1,
        fillOpacity: 0.5,
      });
    } else {
      polygon.setOptions({
        strokeOpacity: 0,
        fillOpacity: 0,
      });
    }
  }
}

/**
 * This function draws all hoverable districts on the map
 * typically from the district selection panel workflow
 * @param {Array<District>} districts
 */
export function drawHoverDistricts(districts) {
  clearDrawnHoverDistricts();
  let polygons = [];

  for (let district of districts) {
    let polygonPath = [];
    let coords = district.geometry.coordinates[0];

    if (district.geometry.type == "MultiPolygon") {
      coords = district.geometry.coordinates[0][0];
    }

    for (const coord of coords) {
      const latLng = new window.google.maps.LatLng(
        parseFloat(coord[1]),
        parseFloat(coord[0]),
      );
      polygonPath.push(latLng);
    }

    let polygon = new window.google.maps.Polygon({
      paths: polygonPath,
      strokeColor: polygonColours.hover,
      strokeOpacity: 1,
      fillColor: polygonColours.hover,
      fillOpacity: 0,
      strokeOpacity: 0,
      draggable: false,
      editable: false,
      strokeWeight: 3,
      zIndex: 2,
    });

    polygon.setMap(getGlobalMapInstance());
    polygon.district = district;

    // add event listeners to the polygon if allowed
    if (district.hoverable) {
      polygon.setOptions({
        zIndex: 3,
      });

      polygon.addListener("mouseover", function (event) {
        document
          .querySelector(".district-cb-container.active")
          ?.classList.remove("active");

        store.dispatch(setHoveredArea(district));
        onHoverOverDistrict(district);
      });

      polygon.addListener("mouseout", function (event) {
        store.dispatch(setHoveredArea(null));
        polygon.setOptions({
          strokeOpacity: 0,
          fillOpacity: 0,
        });
      });

      polygon.addListener("mousemove", function (event) {
        moveHoverInfo(event.latLng);
      });

      polygon.addListener("click", function (event) {
        let { selectedAreas } = store.getState().map;
        if (
          selectedAreas.length == MAX_ALLOWED_AREAS &&
          !selectedAreas.find((a) => a.id == district.id)
        ) {
          toast.error(i18n("Max number of areas reached"));
        } else {
          store.dispatch(toggleSelectedArea(district));
        }
      });
    }

    polygons.push(polygon);
  }

  window.drawnHoverDistricts = polygons;
}

window.selectedActiveGeographicPolygonId = null;

/**
 * Selects the active region on the map
 */
export function selectActiveGeographicPolygon(id) {
  for (let polygon of window.drawnGeographicPolygons) {
    // if the polygon is the selected one, highlight it
    if (polygon.area.id == id) {
      window.selectedActiveGeographicPolygonId = id;

      polygon.setOptions({
        strokeColor: polygonColours.active,
        strokeOpacity: 1,
        fillOpacity: 0,
        strokeWeight: 3,
        clickable: false,
        zIndex: 1,
      });
    } else {
      // turn the lights down on the rest
      polygon.setOptions({
        fillColor: "#000",
        fillOpacity: 0.4,
      });
    }
  }
}

// clear all active geographic polygons
export function clearAllActiveGeographicPolygons() {
  for (let polygon of window.drawnGeographicPolygons) {
    polygon.setOptions({
      strokeColor: polygonColours.primary,
      strokeOpacity: polygon.area.type == "municipality" ? 0.25 : 1,
      fillOpacity: polygon.area.type == "province" ? 0.1 : 0,
      strokeWeight: 1.75,
      clickable: true,
      zIndex: 0,
    });
  }

  window.selectedActiveGeographicPolygonId = null;
}

/**
 * This function draws all districts in the selected state onto the map
 * typically from the district selection panel workflow
 * @param {Array<District>} districts
 */
export function setSelectedDrawnDistricts(districts) {
  for (let polygon of window.drawnSelectedDistricts) {
    polygon.setMap(null);
  }

  window.drawnSelectedDistricts = [];
  let polygons = [];

  for (let district of districts) {
    if (!district.geometry) {
      continue;
    }

    let polygonPath = [];
    let coords = district.geometry.coordinates[0];

    if (district.geometry.type == "MultiPolygon") {
      coords = district.geometry.coordinates[0][0];
    }

    for (const coord of coords) {
      const latLng = new window.google.maps.LatLng(
        parseFloat(coord[1]),
        parseFloat(coord[0]),
      );
      polygonPath.push(latLng);
    }

    let polygon = new window.google.maps.Polygon({
      paths: polygonPath,
      strokeColor: polygonColours.active,
      strokeOpacity: 1,
      fillColor: polygonColours.active,
      fillOpacity: 0.5,
      draggable: false,
      editable: false,
      strokeWeight: 3,
    });

    polygon.setMap(getGlobalMapInstance());
    polygon.district = district;
    polygons.push(polygon);
  }

  window.drawnSelectedDistricts = polygons;
}

// disable clickability on all drawn districts
export function disableAreaClickability() {
  for (let polygon of window.drawnHoverDistricts) {
    polygon.setOptions({
      clickable: false,
    });
  }

  for (let polygon of window.drawnSelectedDistricts) {
    polygon.setOptions({
      clickable: false,
    });
  }

  for (let polygon of window.drawnGeographicPolygons) {
    polygon.setOptions({
      clickable: false,
    });
  }
}

// enable clickability on all drawn districts
export function enableAreaClickability() {
  for (let polygon of window.drawnHoverDistricts) {
    polygon.setOptions({
      clickable: true,
    });
  }

  for (let polygon of window.drawnSelectedDistricts) {
    polygon.setOptions({
      clickable: true,
    });
  }

  for (let polygon of window.drawnGeographicPolygons) {
    polygon.setOptions({
      clickable: true,
    });
  }
}

// Function to animate map zoom
export function animateMapZoom(map, targetZoom) {
  let currentZoom = map.getZoom();
  if (currentZoom !== targetZoom) {
    let step = (targetZoom - currentZoom) / Math.abs(targetZoom - currentZoom);
    let zoomInterval = setInterval(() => {
      currentZoom += step;
      map.setZoom(currentZoom);
      if (currentZoom === targetZoom) {
        clearInterval(zoomInterval);
      }
    }, 100);
  }
}

// Function to animate map pan
export function animateMapPan(map, targetCenter, duration = 1000) {
  const startCenter = map.getCenter();
  const startTime = performance.now();

  function animate() {
    const currentTime = performance.now();
    const elapsedTime = currentTime - startTime;
    const t = Math.min(elapsedTime / duration, 1);

    const lat = startCenter.lat() + t * (targetCenter.lat - startCenter.lat());
    const lng = startCenter.lng() + t * (targetCenter.lng - startCenter.lng());

    map.setCenter(new window.google.maps.LatLng(lat, lng));

    if (t < 1) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
}

export async function searchMapForReferences(query, page) {
  const trimmedQuery = query.trim();

  store.dispatch(setCmaProperty(null));
  store.dispatch(setProperties([]));

  if (trimmedQuery.startsWith("http")) {
    // searching property url
    this.onUrlSearch(trimmedQuery);
  } else if (extractNumbersFromString(trimmedQuery).length > 5) {
    // else its a catastral ref so search by that
    // but first get the plot its refcat is tied to
    let plot = await getPlotByRefCat(trimmedQuery.substring(0, 14));
    if (plot) {
      // draw and pan to catastral plot on map
      drawSingleSelectedPlot(plot);
      store.dispatch(setSelectedPlot(plot));
      let map = getGlobalMapInstance();
      map.panTo({
        lat: parseFloat(plot.center_y),
        lng: parseFloat(plot.center_x),
      });
      map.setZoom(page === "cma" ? 18 : 17);
      // this.onShiftMapForCmaPanel();
    }
  } else {
    // else just do a google maps search
    this.onAddressSearch(trimmedQuery, ZOOM_LEVEL_TO_SHOW_PLOTS);
  }
}

// convert the multi ref to a cma property
export function catastroToCmaProperty(catastro, ref, lat, lng, selectedPlot) {
  let fullRef = ref;
  let address = "";
  let buildingType = "property";
  let zipCode = "";
  let type = i18n("Residential");
  let surfaceTotal = parseInt(selectedPlot.area);
  let buildingSubType = null;

  // from a multi ref catastro source so fill out entire ref
  if (catastro.rc && catastro.rc.pc1) {
    let rc = catastro.rc;
    fullRef = rc.pc1 + rc.pc2 + rc.car + rc.cc1 + rc.cc2;
  }

  // build full ref from this idbi.rc object
  if (catastro.idbi && catastro.idbi.rc) {
    let rc = catastro.idbi.rc;
    fullRef = rc.pc1 + rc.pc2 + rc.car + rc.cc1 + rc.cc2;
  }

  // commercial type
  if (
    catastro.debi?.luso?.includes("Comercial") ||
    catastro.debi?.luso?.includes("Almacen") ||
    catastro.debi?.luso?.includes("Industrial")
  ) {
    type = i18n("Commercial");
    buildingType = "commercial";
  }

  // land type
  if (
    catastro.debi?.luso.includes("suelos sin edificar") ||
    catastro.debi?.luso.includes("Agrario")
  ) {
    type = i18n("Land");
    buildingType = "land";
    if (catastro.debi.luso.includes("Agrario")) {
      buildingSubType = "land.unbuildable";
    } else {
      buildingSubType = "land.urban";
    }
  }

  if (catastro.dt?.locs.lous && buildingType != "land") {
    let lourb = catastro.dt.locs.lous.lourb;
    let dir = lourb.dir;
    address = `${dir.pnp} ${toCapitalCase(dir.nv)} Escalera: ${lourb.loint.es} Planta: ${lourb.loint.pt} Puerta: ${lourb.loint.pu}`;
    zipCode = catastro.dt.locs.lous.lourb.dp;
  }

  return {
    ref: fullRef,
    address,
    zip_code: zipCode,
    size: buildingType == "land" ? surfaceTotal : parseInt(catastro.debi?.sfc),
    plotSize: surfaceTotal,
    url: catastro.url,
    type: type,
    typology: null,
    buildingSubType,
    latitude: lat,
    longitude: lng,
    isCatastro: true,
    isCmaProperty: true,
    buildingType,
    features: [],
    status: null,
    province: toCapitalCase(catastro.dt?.np.toLowerCase()),
    municipality: toCapitalCase(catastro.dt?.nm.toLowerCase()),
    url: `https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?del=${catastro.dt?.loine.cp}&mun=${catastro.dt?.cmc}&RefC=${fullRef}`,
    country: "es",
    saleType: "sale",
  };
}
