import { useJsApiLoader } from "@react-google-maps/api";
import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PUBLIC_MEDIA_BASE_URL from "utils/file";
import { getCatastralByRefDirect } from "../../../api/cma";
import likeIcon from "../../../assets/core/like_outline.svg";
import wcIcon from "../../../assets/core/wc_black.svg";
import likeIconLiked from "../../../assets/map/favourite_icon_hovered.svg";
import threeSixtyIcon from "../../../assets/property/360.svg";
import floorplanIcon from "../../../assets/property/floorplan.svg";
import threeSixtyIconHighlighted from "../../../assets/property/highlighted/360.svg";
import floorplanIconHighlighted from "../../../assets/property/highlighted/floorplan.svg";
import imageIconHighlighted from "../../../assets/property/highlighted/image.svg";
import videoIconHighlighted from "../../../assets/property/highlighted/video.svg";
import imageIcon from "../../../assets/property/image.svg";
import locationIcon from "../../../assets/property/location.svg";
import priceChangeNegativeIcon from "../../../assets/property/price_change_negative.svg";
import videoIcon from "../../../assets/property/video.svg";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_LIBRARIES,
} from "../../../config/constants";
import {
  formatNumber,
  getPriceChangePercentage,
  getPricePerM2,
  openCatastroRef,
} from "../../../utils/helpers";
import {
  getFeaturesFromProperty,
  getPropertyTimeOnMarket,
  getPropertyTitle,
  isPropertyFromDataSource,
} from "../../../utils/properties";
import FullscreenButton from "../../core/FullscreenButton";
import FullscreenContainer from "../../core/FullscreenContainer";
import MediaCarousel from "../../core/MediaCarousel";
import Button from "../../ui/Button/Button";
import PropertyFeatureChip from "../PropertyFeatureChip";
import GeolocatorView from "./GeolocatorView";
import WindowsCardModal from "./WindowsCard/WindowsCardModal";
import GalleryView from "components/core/GalleryView";

const MEDIA_MODE = {
  IMAGE_GALLERY: 0,
  VIDEO_GALLERY: 1,
  VIRTUAL_TOUR: 2,
  FLOOR_PLAN_GALLERY: 3,
};

const MainPropertyDetailsCard = (props) => {
  const { property, duplicates } = props;
  const [mediaMode, setMediaMode] = useState(MEDIA_MODE.IMAGE_GALLERY);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isWindowsCardModalOpen, setIsWindowsCardModalOpen] = useState(false);
  let cardElement = useRef(null);

  useEffect(() => {
    if (fullscreenMode || showMap) {
      const onKeyDown = (e) => {
        if (e.key === "Escape") {
          setFullscreenMode(false);
          setShowMap(false);
          window.removeEventListener("keydown", onKeyDown);
        }
      };

      window.addEventListener("keydown", onKeyDown);
    }
  }, [fullscreenMode, showMap]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  let images = [];
  let videos = [];
  let floorPlans = [];
  let virtualTour = null;

  // check if there are any images tied to the property before assignment
  if (property.multimedia && property.multimedia.images) {
    images = property.multimedia.images.map((image) => image.url);
  }

  // check if there are any videos tied to the property before assignment
  if (property.multimedia && property.multimedia.videos) {
    videos = property.multimedia.videos;
  }

  // check if there are any floor plans tied to the property before assignment
  if (property.multimedia && property.multimedia.plans) {
    floorPlans = property.multimedia.plans.map((plan) => plan.url);
  }

  // check for virtual tour
  if (property.multimedia && property.multimedia.virtualTour) {
    virtualTour = property.multimedia.virtualTour;
  }

  const title = getPropertyTitle(property);
  const price = formatNumber(property.price);
  const pricePerM2 = getPricePerM2(property);
  let priceReductionPercentage = getPriceChangePercentage(property);

  if (priceReductionPercentage > 0) {
    priceReductionPercentage = 0;
  }

  let sellerType = i18n("Agency");

  if (property.contactInfo && property.contactInfo.userType) {
    sellerType =
      property.contactInfo.userType === "private"
        ? i18n("Owner")
        : i18n("Agency");
  }

  let previousPrice = null;
  if (
    property.priceHistory &&
    property.priceHistory.length > 0 &&
    !isPropertyFromDataSource(property, "airbnb")
  ) {
    previousPrice = formatNumber(
      property.priceHistory[property.priceHistory.length - 1].price,
    );
  }

  const features = getFeaturesFromProperty(property);
  const isLiked = props.collections.find((c) =>
    c.properties.find((p) => p.id === property.id),
  );

  const renderMediaMode = () => {
    let mediaCarousel = null;
    let fullscreenMediaCarousel = null;

    if (mediaMode === MEDIA_MODE.IMAGE_GALLERY) {
      mediaCarousel = (
        <MediaCarousel
          allowArrowNavigation={!isWindowsCardModalOpen}
          onOpenFullscreen={() => setFullscreenMode(true)}
          images={images}
        />
      );
      fullscreenMediaCarousel = <GalleryView images={images} />;
    }

    if (mediaMode === MEDIA_MODE.VIDEO_GALLERY) {
      mediaCarousel = (
        <MediaCarousel
          allowArrowNavigation={!isWindowsCardModalOpen}
          onOpenFullscreen={() => setFullscreenMode(true)}
          hideDots
          videos={videos}
          hideFullscreenButton
        />
      );
    }

    if (mediaMode === MEDIA_MODE.FLOOR_PLAN_GALLERY) {
      mediaCarousel = (
        <MediaCarousel
          allowArrowNavigation={!isWindowsCardModalOpen}
          onOpenFullscreen={() => setFullscreenMode(true)}
          images={floorPlans}
        />
      );
      fullscreenMediaCarousel = (
        <MediaCarousel
          allowArrowNavigation
          hideFullscreenButton
          images={floorPlans}
        />
      );
    }

    if (mediaMode === MEDIA_MODE.VIRTUAL_TOUR) {
      if (virtualTour) {
        mediaCarousel = (
          <div className="property-virtual-tour-container">
            <iframe
              className="property-virtual-tour"
              src={virtualTour.url}
              title={virtualTour.url}
            />
            <FullscreenButton
              onOpenFullscreen={() => setFullscreenMode(true)}
            />
          </div>
        );

        fullscreenMediaCarousel = (
          <iframe
            className="property-virtual-tour"
            src={virtualTour.url}
            title={virtualTour.url}
          />
        );
      }
    }

    if (fullscreenMode && fullscreenMediaCarousel) {
      return (
        <>
          <FullscreenContainer
            onCloseFullscreen={() => setFullscreenMode(false)}
          >
            {fullscreenMediaCarousel}
          </FullscreenContainer>
          {mediaCarousel}
        </>
      );
    }

    return mediaCarousel;
  };

  const valuateProperty = async () => {
    let url = `/valuation?id=${property.id}`;
    let tabId = "valuation_" + property.id;

    if (property.saleType === "rent") {
      url = `/valuation?id=${property.id}&sale_type=rent`;
      tabId = "valuation_rent_" + property.id;
    }

    window.open(url, tabId);
  };

  const canValuateProperty = () => {
    return (
      (property.buildingType == "property" ||
        property.buildingType == "land" ||
        property.buildingType == "commercial") &&
      !property.rental_isShortTerm
    );
  };

  const renderPropertyTimeOnMarket = () => {
    return (
      <>
        <div className="vertical-divider"></div>
        <span>
          {getPropertyTimeOnMarket(property) > 1
            ? getPropertyTimeOnMarket(property) +
              " " +
              i18n("days") +
              " " +
              i18n("on the market")
            : getPropertyTimeOnMarket(property) +
              " " +
              i18n("day") +
              " " +
              i18n("on the market")}
        </span>
      </>
    );
  };

  const renderPropertySize = () => {
    if (property.buildingType == "land") {
      return (
        <>
          <span>
            {formatNumber(property.size)}
            {i18n("m² surface total")}
          </span>
          {property.land_minSaleSize && (
            <>
              <div className="vertical-divider"></div>
              <span>
                {formatNumber(property.land_minSaleSize)}
                {i18n("m² min sale size")}
              </span>
            </>
          )}
          {property.land_buildableSize && (
            <>
              <div className="vertical-divider"></div>
              <span>
                {formatNumber(property.land_buildableSize)}
                {i18n("m² buildable size")}
              </span>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <span>
          {formatNumber(property.size)}
          {i18n("m² built")}
        </span>
        {property.plotSize && property.plotSize > 0 && (
          <>
            <div className="vertical-divider"></div>
            <span>
              {formatNumber(property.plotSize)}
              {i18n("m² total plot")}
            </span>
          </>
        )}
      </>
    );
  };

  return (
    <div
      ref={cardElement}
      id={"property-" + property.id}
      className="property-card details-card"
    >
      {renderMediaMode()}
      <div className="property-card_contents">
        <div className="property-card_contents_controls">
          <div className="property-card_contents_controls_media-buttons">
            {images.length >= 1 && (
              <div
                className={
                  "primary-btn" +
                  (mediaMode === MEDIA_MODE.IMAGE_GALLERY ? " active" : "")
                }
                onClick={() => setMediaMode(MEDIA_MODE.IMAGE_GALLERY)}
              >
                <img
                  src={
                    mediaMode === MEDIA_MODE.IMAGE_GALLERY
                      ? imageIconHighlighted
                      : imageIcon
                  }
                  alt=""
                />
              </div>
            )}
            {videos.length >= 1 && (
              <div
                className={
                  "primary-btn" +
                  (mediaMode === MEDIA_MODE.VIDEO_GALLERY ? " active" : "")
                }
                onClick={() => setMediaMode(MEDIA_MODE.VIDEO_GALLERY)}
              >
                <img
                  src={
                    mediaMode === MEDIA_MODE.VIDEO_GALLERY
                      ? videoIconHighlighted
                      : videoIcon
                  }
                  alt=""
                />
              </div>
            )}
            {virtualTour !== null && (
              <div
                className={
                  "primary-btn" +
                  (mediaMode === MEDIA_MODE.VIRTUAL_TOUR ? " active" : "")
                }
                onClick={() => setMediaMode(MEDIA_MODE.VIRTUAL_TOUR)}
              >
                <img
                  src={
                    mediaMode === MEDIA_MODE.VIRTUAL_TOUR
                      ? threeSixtyIconHighlighted
                      : threeSixtyIcon
                  }
                  alt=""
                />
              </div>
            )}
            {floorPlans.length >= 1 && (
              <div
                className={
                  "primary-btn" +
                  (mediaMode === MEDIA_MODE.FLOOR_PLAN_GALLERY ? " active" : "")
                }
                onClick={() => setMediaMode(MEDIA_MODE.FLOOR_PLAN_GALLERY)}
              >
                <img
                  src={
                    mediaMode === MEDIA_MODE.FLOOR_PLAN_GALLERY
                      ? floorplanIconHighlighted
                      : floorplanIcon
                  }
                  alt=""
                />
              </div>
            )}
          </div>
          <div className="property-card_contents_controls_functions">
            {property.refcat && property.refcat.toLowerCase() !== "none" && (
              <Button
                onClick={() => openCatastroRef(property.refcat)}
                variant="secondary"
              >
                <span>{i18n("Catastro")}</span>
              </Button>
            )}
            <Button
              onClick={() => setShowMap(true)}
              variant="secondary"
              className="icon-btn geolocator-btn"
            >
              <img src={locationIcon} alt="Location" />
              <span>{i18n("Location")}</span>
            </Button>
            <Button
              onClick={() => setIsWindowsCardModalOpen(true)}
              variant="secondary"
              className="icon-btn wc-btn"
            >
              <img src={wcIcon} alt="Location" />
              <span>{i18n("WC")}</span>
            </Button>
            {canValuateProperty() && (
              <Button
                className="icon-btn"
                onClick={() => valuateProperty()}
                variant="primary"
              >
                <span>{i18n("Valuate")}</span>
              </Button>
            )}
          </div>
        </div>
        <div className="horizontal-divider"></div>
        <div className="property-card_contents_section1">
          <p className="property-card-title">{title}</p>
          <div className="property-card_contents_section1_prices">
            <div className="property-card_contents_section1_prices_main_price">
              <span
                className="full-price"
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  flexBasis: "auto",
                }}
              >
                {parseFloat(price) > 0 ? (
                  <>
                    {price} €
                    {property.saleType === "rent" &&
                      !property.rental_isShortTerm && (
                        <small> / {i18n("month")}</small>
                      )}
                    {property.saleType === "rent" &&
                      property.rental_isShortTerm && (
                        <small> / {i18n("day")}</small>
                      )}
                  </>
                ) : (
                  i18n("Price on request")
                )}
              </span>
              {/* {property.isPrivateBankProperty && (
                <div className="property-card_value_chip_container">
                  <div className="property-card_value_chip">
                    <span role="img" aria-label="check">
                      {i18n("Great Value")}
                    </span>
                    <img src={greatValueIcon} alt="Great Value Icon" />
                  </div>
                </div>
              )} */}
              {property.saleType !== "rent" &&
                parseFloat(price) > 0 &&
                parseFloat(property.size) > 0 && (
                  <span className="price-per-m">{pricePerM2} €/㎡</span>
                )}
            </div>
            {priceReductionPercentage < 0 &&
              !isPropertyFromDataSource(property, "airbnb") && (
                <div className="property-card_contents_section1_prices_was_price">
                  <span className="was-price">
                    {i18n("was")} {previousPrice} €
                    {property.saleType === "rent" && (
                      <small> / {i18n("month")}</small>
                    )}
                  </span>
                  <div className="was-price-diff">
                    <img src={priceChangeNegativeIcon} alt="Price reduction" />
                    <span>{Math.abs(priceReductionPercentage)}%</span>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="horizontal-divider"></div>
        <div className="property-card-chips">
          {features.map((feature, index) => (
            <PropertyFeatureChip key={index} feature={feature} />
          ))}
        </div>
        {!(property.isPrivateBankProperty && property.size == 0) &&
          !property.rental_isShortTerm && (
            <div className="property-card-size">
              {renderPropertySize()} {renderPropertyTimeOnMarket()}
            </div>
          )}
        {property.rental_isShortTerm && (
          <div className="property-card-size">{i18n("Short term rental")}</div>
        )}
        <div className="horizontal-divider"></div>
        <div className="property-card_footer">
          <span>
            {property.isPrivateBankProperty && property.privateBank_assetType
              ? property.privateBank_assetType.toUpperCase()
              : sellerType}
          </span>
          {isLiked ? (
            <img
              className="property-card_like"
              src={likeIconLiked}
              onClick={props.onUnlikeProperty}
              alt=""
            />
          ) : (
            <img
              className="property-card_like"
              src={likeIcon}
              onClick={props.onLikeProperty}
              alt=""
            />
          )}
        </div>
      </div>
      {showMap && (
        <GeolocatorView property={property} setShowMap={setShowMap} />
      )}
      <WindowsCardModal
        isOpen={isWindowsCardModalOpen}
        closeModal={() => setIsWindowsCardModalOpen(false)}
        property={property}
        duplicates={duplicates}
      />
    </div>
  );
};

export default connect((state) => ({
  collections: state.collections.collections,
}))(MainPropertyDetailsCard);
