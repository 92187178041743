import { getPropertyTitle } from "utils/properties";
import { getLang, i18n } from "../../i18n/localisation";
import { Tooltip } from "react-tooltip";
import rightChevron from "../../assets/core/right_chevron.svg";
import { selectPropertyCard } from "utils/ui";
import {
  capitalizeWord,
  debouncedMapPanTo,
  debouncedMapSetZoom,
  formatNumber,
  formatPrice,
  openCatastroRef,
  randomNumberWithStep,
} from "utils/helpers";
import { getGlobalMapInstance, panMapByPixels } from "utils/map";
import moment from "moment";
import "moment/locale/es";
import { connect, useDispatch } from "react-redux";
import { getSubscriptionPlan } from "utils/userHelpers";
import { setPricingModal } from "actions/miscActions";

function SoldPropertyCard({ property, selectPropertyMarker, drawnPolygons }) {
  const lang = getLang();
  const soldDate = capitalizeWord(
    moment
      .unix(parseInt(property.timestamp))
      .locale(lang.code)
      .format("MMM YYYY"),
  );
  const price = formatNumber(parseFloat(property.price));
  const pricePerM2 = formatNumber(
    parseInt(parseFloat(property.price) / parseFloat(property.size)),
  );
  const polygon = drawnPolygons.find(
    (polygon) => polygon.irealtyId === property.polygonId,
  );
  const dispatch = useDispatch();

  const onClick = (e) => {
    const selectedCard = e.currentTarget;
    const map = getGlobalMapInstance();
    debouncedMapSetZoom(map, 18);
    debouncedMapPanTo(map, {
      lat: parseFloat(property.latitude),
      lng: parseFloat(property.longitude),
    });

    // center the property between so it doesn't encroach too much on the sidepanel
    let sidePanel = document.getElementById("property-panel");
    let sidePanelWidth = sidePanel.offsetWidth;
    setTimeout(() => {
      panMapByPixels(map, sidePanelWidth / 2, 0);
    }, 250);

    selectPropertyMarker(property.id);

    // select the card
    document.querySelectorAll(".sold-property-panel-card").forEach((el) => {
      if (el.id != selectedCard.id) {
        el.classList.remove("selected");
      }
    });

    selectedCard.classList.add("selected");
  };

  const onCatastroClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    openCatastroRef(property.refcat);
  };

  const openPricingModal = () => {
    dispatch(setPricingModal(true));
  };

  // do not show the card if the user is on a free plan
  if (getSubscriptionPlan() === null) {
    return (
      <div
        className="sold-property-panel-card blurred"
        onClick={openPricingModal}
      >
        <div className="sold-property-panel-card_header">
          <div className="sold-property-panel-card_header_badge">
            <span>{i18n("Sold")}</span>
          </div>
          <div className="sold-property-panel-card_header_right">
            <span>{i18n("Upgrade to view")}</span>
          </div>
        </div>
        <div className="divider"></div>
        <div className="sold-property-panel-card_body">
          <div className="sold-property-panel-card_body_title">
            <span>{i18n("Upgrade to view")}</span>
          </div>
          <span className="sold-property-panel-card_body_sold-for">
            {i18n("Sold for")}:
          </span>
          <span className="sold-property-panel-card_body_sold-for-price">
            <span className="blurred-text">
              {formatPrice(randomNumberWithStep(1000000, 10000000, 100000))}
            </span>
            <span className="blurred-text">
              ({formatPrice(randomNumberWithStep(100, 1000, 10))}/㎡)
            </span>
          </span>
          <div className="sold-property-panel-card_body_size">
            <span className="blurred-text">
              {randomNumberWithStep(100, 1000, 10)}m² {i18n("built")}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id={"sold-property-" + property.id}
      className="sold-property-panel-card"
      onClick={onClick}
    >
      <div className="sold-property-panel-card_header">
        <div className="sold-property-panel-card_header_badge">
          <span>{i18n("Sold")}</span>
        </div>
        <div className="sold-property-panel-card_header_right">
          <span>{i18n("Sold in")}:</span>
          <span>{soldDate}</span>
        </div>
      </div>
      <div className="divider"></div>
      <div className="sold-property-panel-card_body">
        <div className="sold-property-panel-card_body_title">
          <span>{getPropertyTitle(property)}</span>
        </div>
        <span className="sold-property-panel-card_body_sold-for">
          {i18n("Sold for")}:
        </span>
        <span className="sold-property-panel-card_body_sold-for-price">
          <span>{price} €</span>
          <span>({pricePerM2} €/㎡)</span>
        </span>
        <div className="sold-property-panel-card_body_size">
          <span>
            {property.size}m² {i18n("built")}
          </span>
        </div>
      </div>
      <div className="divider"></div>
      <div className="sold-property-panel-card_footer">
        <div className="sold-property-panel-card_footer_poligon-dot-content">
          <div
            className="property-panel-card_footer_poligon-dot-content"
            data-tooltip-id={`polygon-selection-tooltip-polygon-${property.id}`}
            data-tooltip-content={
              polygon && polygon.metadata
                ? polygon.metadata.name
                : i18n("Custom area")
            }
          >
            <div className="polygon-selection-tray_row_item">
              <div
                className="polygon-selection-tray_row_item_circle"
                style={{
                  backgroundColor: polygon ? polygon.colourCode : "#fff",
                }}
              ></div>
              <Tooltip
                className="chip-tooltip"
                id={`polygon-selection-tooltip-polygon-${property.id}`}
              />
            </div>
          </div>
        </div>
        <div
          className="sold-property-panel-card_footer_catastro"
          onClick={onCatastroClick}
        >
          <span>{i18n("Catastro")}</span>
          <img src={rightChevron} alt="Catastro" />
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
}))(SoldPropertyCard);
