import {
  setDupeProperty,
  setMarkDupeMode,
  setMarkLocationMode,
  setMarkLocationProperty,
} from "actions/adminActions";
import { deleteProperties } from "actions/propertiesActions";
import { markPropertyAsDupe } from "api/admin";
import Button from "components/ui/Button/Button";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { i18n } from "i18n/localisation";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { toast } from "sonner";
import likeIcon from "../../assets/core/like_outline.svg";
import likeIconLiked from "../../assets/map/favourite_icon_hovered.svg";
import bankPropertyIcon from "../../assets/property/bank_property.svg";
import greatValueIcon from "../../assets/property/great_value.svg";
import { CollectionsContext } from "../../context/CollectionsContext";
import {
  debounce,
  debouncedMapPanTo,
  debouncedMapSetZoom,
  formatNumber,
  getPriceDropPercentage,
  getPricePerM2,
  isMobile,
} from "../../utils/helpers";
import { getGlobalMapInstance, panMapByPixels } from "../../utils/map";
import {
  getFeaturesFromProperty,
  getPropertyTitle,
} from "../../utils/properties";
import { selectPropertyCard } from "../../utils/ui";
import MediaCarousel from "../core/MediaCarousel";
import PropertyFeatureChip, {
  PROPERTY_FEATURE_TYPE,
} from "./PropertyFeatureChip";
import UpgradeView from "./UpgradeView";

import { setPricingModal } from "actions/miscActions";
import { sendAnalyticsEvent } from "lib/analytics";
import { userHasRole } from "utils/userHelpers";

const PropertyCard = (props) => {
  const {
    property,
    isSelectable,
    onSelect,
    selected,
    user,
    admin,
    drawnPolygons,
  } = props;
  const [isInCollection, setIsInCollection] = useState(false);

  const { openAddToCollectionModal } = useContext(CollectionsContext);

  const polygon = drawnPolygons.find(
    (polygon) => polygon.irealtyId === property.polygonId,
  );

  let cardElement = useRef(null);
  let images = [];

  // check if there are any images tied to the property before assignment
  if (property.multimedia && property.multimedia.images) {
    images = property.multimedia.images.map((image) => image.url);
  }

  const title = getPropertyTitle(property);
  const price = formatNumber(property.price);
  const pricePerM2 = getPricePerM2(property);

  const priceReductionPercentage = getPriceDropPercentage(property);
  let sellerType = "Agency";

  if (property.contactInfo && property.contactInfo.userType) {
    sellerType =
      property.contactInfo.userType === "private"
        ? i18n("Owner")
        : i18n("Agency");
  }

  let previousPrice = null;
  if (property.priceDropValue) {
    previousPrice = formatNumber(
      parseFloat(property.price) + parseFloat(property.priceDropValue),
    );
  }

  const features = getFeaturesFromProperty(property);

  // go to the property and select the marker on card click
  const onPropertyCardClick = (e) => {
    if (isSelectable) {
      sendAnalyticsEvent("Property Card Click", {
        type: "select property",
        details: property,
      });
      return onSelect(property);
    }

    if (
      property.isPrivateBankProperty &&
      !userHasRole("private_bank_properties")
    ) {
      // if user doesn't have permission to view private bank properties, show upgrade view
      e.preventDefault();
      e.stopPropagation();
      onOpenPricingModal();

      sendAnalyticsEvent("Property Card Click", {
        type: "view private bank property",
        details: property,
      });
      return;
    }

    if (props.disablePropertyMarkerInteraction) {
      e.preventDefault();
      sendAnalyticsEvent("Property Card Click", {
        type: "view property",
        details: property,
      });
      return window.open(
        `/${property.saleType == "rent" ? "rental" : "property"}/${property.id}`,
        "_blank",
      );
    }

    const map = getGlobalMapInstance();
    debouncedMapSetZoom(map, 18);
    debouncedMapPanTo(map, {
      lat: parseFloat(property.latitude),
      lng: parseFloat(property.longitude),
    });

    // center the property between so it doesn't encroach too much on the sidepanel
    let sidePanel = document.getElementById("property-panel");
    let sidePanelWidth = sidePanel.offsetWidth;
    setTimeout(() => {
      panMapByPixels(map, sidePanelWidth / 2, 0);
    }, 250);

    props.selectPropertyMarker(property.id);
    selectPropertyCard(cardElement.current);
  };

  // add box shadow effects to the hovered property card
  const onMouseOver = () => {
    document.querySelectorAll(".property-panel-card").forEach((el) => {
      if (el.id !== cardElement.current.id) {
        el.classList.remove("hovered");
      }
    });

    cardElement.current.classList.add("hovered");

    // also highlight the map marker that matches the current property card
    let marker = document.querySelector("#property-marker-" + property.id);
    if (marker) {
      marker.classList.add("hovered");
    }

    // also highlight any map clusters that contain the current property
    let mapClusters = document.querySelectorAll(".map-cluster");
    for (let mapCluster of mapClusters) {
      let propertiesList = mapCluster.getAttribute("data-properties");

      if (propertiesList) {
        let propertyIds = propertiesList.split(",");
        if (propertyIds.includes(property.id)) {
          mapCluster.classList.add("hovered");
        }
      }
    }
  };

  // unhighlight all property markers on mouse out
  const onMouseOut = () => {
    document
      .querySelectorAll(".property-marker, .map-cluster")
      .forEach((el) => {
        el.classList.remove("hovered");
      });
  };

  // heart icon click
  const handleTogglePropertyInCollection = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      property.isPrivateBankProperty &&
      !userHasRole("private_bank_properties")
    ) {
      // if user doesn't have permission to view private bank properties, show upgrade view
      onOpenPricingModal();
      sendAnalyticsEvent("Property Card Click", {
        type: "save private bank property",
        details: property,
      });
      return;
    }

    sendAnalyticsEvent("Property Card Click", {
      type: "save property",
      details: property,
    });
    const addToCollectionObject = { ...property, objectType: "property" };
    openAddToCollectionModal(addToCollectionObject);
  };

  useEffect(() => {
    if (props.collections) {
      let isInCollection = false;

      for (let collection of props.collections) {
        for (let propertyInCollection of collection.properties) {
          if (propertyInCollection.id === property.id) {
            isInCollection = true;
            break;
          }
        }
      }

      setIsInCollection(isInCollection);
    }
  }, [props.collections]);

  const onMarkDupeClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toast.message("Select the parent property to mark as dupe");
    props.dispatch(setMarkDupeMode(true));
    props.dispatch(setDupeProperty(property));
  };

  const onMarkParentClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { admin } = props;

    markPropertyAsDupe(property.id, admin.dupeProperty.id).then(() => {
      props.dispatch(deleteProperties([admin.dupeProperty]));
      props.dispatch(setMarkDupeMode(false));
      props.dispatch(setDupeProperty(null));
      toast.message("Dupe marked successfully");
    });
  };

  const renderDupeMarker = () => {
    if (
      props.user.userData.permissions &&
      props.user.userData.permissions.mark_dupe
    ) {
      if (admin.markDupeMode) {
        return (
          <Button
            style={{ padding: 0, fontSize: 10 }}
            onClick={onMarkParentClicked}
          >
            Mark Parent
          </Button>
        );
      } else {
        return (
          <Button
            variant="text"
            style={{ padding: 0, fontSize: 10 }}
            onClick={onMarkDupeClicked}
          >
            Mark Dupe
          </Button>
        );
      }
    }
  };

  const onMapPin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.dispatch(setMarkLocationMode(true));
    props.dispatch(setMarkLocationProperty(property));
    toast.message("Select a location on the map to pin the property");
  };

  const featuresTooltipId = "features-tooltip-" + property.id;

  const onOpenPricingModal = () => {
    console.log("open pricing modal");
    props.dispatch(setPricingModal(true));
  };

  const renderFeatures = () => {
    if (property.isPrivateBankProperty) {
      return (
        <div className="property-card-chips">
          <PropertyFeatureChip
            feature={{
              type: PROPERTY_FEATURE_TYPE.privateBank_propertyStatus,
              value: property.privateBank_propertyStatus,
            }}
          />
        </div>
      );
    }

    if (
      property.buildingType === "land" ||
      property.buildingType === "commercial" ||
      property.buildingType === "garage" ||
      property.buildingType === "storage" ||
      property.buildingType === "building" ||
      property.buildingType === "office"
    ) {
      return (
        <div className="property-card-chips">
          {features
            .map((feature, index) => (
              <PropertyFeatureChip key={index} feature={feature} />
            ))
            .slice(0, 1)}
          {features.slice(1).length > 0 && (
            <PropertyFeatureChip
              data-tooltip-id={featuresTooltipId}
              feature={{
                type: PROPERTY_FEATURE_TYPE.more,
                value: `${features.slice(1).length} ${i18n("more")}`,
              }}
            />
          )}
        </div>
      );
    }

    return (
      <div className="property-card-chips">
        {features
          .map((feature, index) => (
            <PropertyFeatureChip key={index} feature={feature} />
          ))
          .slice(0, 2)}
        {features.slice(2).length > 0 && (
          <PropertyFeatureChip
            data-tooltip-id={featuresTooltipId}
            feature={{
              type: PROPERTY_FEATURE_TYPE.more,
              value: `${features.slice(2).length} ${i18n("more")}`,
            }}
          />
        )}
      </div>
    );
  };

  const renderPropertySize = () => {
    if (property.buildingType === "land") {
      return (
        <>
          <span>
            {formatNumber(property.size)}
            {i18n("m² surface total")}
          </span>
        </>
      );
    }

    return (
      <>
        <span>
          {formatNumber(property.size)}
          {i18n("m² built")}
        </span>
        {property.plotSize && property.plotSize > 0 && (
          <>
            <div className="vertical-divider"></div>
            <span>
              {formatNumber(property.plotSize)}
              {i18n("m² plot")}
            </span>
          </>
        )}
      </>
    );
  };

  const renderTooltips = () => {
    if (
      property.buildingType === "land" ||
      property.buildingType === "commercial" ||
      property.buildingType === "garage" ||
      property.buildingType === "storage" ||
      property.buildingType === "building" ||
      property.buildingType === "office"
    ) {
      return (
        <Tooltip className="chip-tooltip" id={featuresTooltipId}>
          {features.slice(1).map((f, index) => (
            <div key={index}>{f.value}</div>
          ))}
        </Tooltip>
      );
    }

    return (
      <Tooltip className="chip-tooltip" id={featuresTooltipId}>
        {features.slice(2).map((f, index) => (
          <div key={index}>{f.value}</div>
        ))}
      </Tooltip>
    );
  };

  const renderSimilarityScore = () => {
    if (property.similarityScore || property.similarityScore === 0) {
      const similarityPercantage = parseInt(property.similarityScore * 100);
      const tooltipText = `${similarityPercantage}% ${i18n("similarity")}`;
      const tooltipId = "similarity-tooltip-" + property.id;

      if (property.similarityScore >= 0.9) {
        return (
          <div
            className="property-panel-card_similarity-score"
            data-tooltip-id={tooltipId}
          >
            <div className="property-panel-card_similarity-score_circle high"></div>
            <span>{similarityPercantage}%</span>
            <Tooltip className="chip-tooltip" id={tooltipId}>
              <div>{tooltipText}</div>
            </Tooltip>
          </div>
        );
      }

      if (property.similarityScore >= 0.7) {
        return (
          <div
            className="property-panel-card_similarity-score"
            data-tooltip-id={tooltipId}
          >
            <div className="property-panel-card_similarity-score_circle medium"></div>
            <span>{similarityPercantage}%</span>
            <Tooltip className="chip-tooltip" id={tooltipId}>
              <div>{tooltipText}</div>
            </Tooltip>
          </div>
        );
      }

      return (
        <div
          className="property-panel-card_similarity-score"
          data-tooltip-id={tooltipId}
        >
          <div className="property-panel-card_similarity-score_circle low"></div>
          <span>{similarityPercantage}%</span>
          <Tooltip className="chip-tooltip" id={tooltipId}>
            <div>{tooltipText}</div>
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <div
      ref={cardElement}
      id={"property-" + property.id}
      className="property-panel-card"
      onClick={onPropertyCardClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {isSelectable && (
        <div className="property-panel-card_checkbox">
          <Checkbox fillOpacityUnchecked="1" checked={selected} />
        </div>
      )}
      {renderSimilarityScore()}

      {/* Chips - only show if it's a bank property */}
      {property.isPrivateBankProperty && (
        <div className="property-panel-card-chips-container">
          <div className="chip chip-left">
            <img src={bankPropertyIcon} alt="" />
            <span>{i18n("Bank Property")}</span>
          </div>
          {/* <div className="chip chip-right">
            <span role="img" aria-label="check">
              {i18n("Great Value")}
            </span>
            <img src={greatValueIcon} alt="" />
          </div> */}
        </div>
      )}
      {!userHasRole("private_bank_properties") &&
      property.isPrivateBankProperty ? (
        <a
          target={property.id}
          className="property-panel-card-title-private-bank"
        >
          <UpgradeView />
        </a>
      ) : (
        <a
          href={
            isSelectable
              ? null
              : `/${property.saleType == "rent" ? "rental" : "property"}/${property.id}`
          }
          target={property.id}
          className="property-panel-card-title"
        >
          <MediaCarousel
            hideFullscreenButton
            lazyLoad={!isMobile()}
            images={images}
            propertyId={property.id}
          />
        </a>
      )}

      <div className="property-panel-card_contents">
        <div className="property-panel-card_contents_section1">
          <a
            href={`/${property.saleType === "rent" ? "rental" : "property"}/${property.id}`}
            onClick={(e) => {
              e.stopPropagation();

              // prevent user from accessing private bank properties if they don't have the permission
              if (
                property.isPrivateBankProperty &&
                !userHasRole("private_bank_properties")
              ) {
                e.preventDefault();
              }
            }}
            target={property.id}
            className="property-panel-card-title"
          >
            {title}
          </a>
          <div className="property-panel-card_contents_section1_prices">
            <div className="property-panel-card_contents_section1_prices_main_price">
              <span className="full-price">
                {parseFloat(price) > 0 ? (
                  <>
                    {price} €
                    {property.saleType === "rent" &&
                      !property.rental_isShortTerm && (
                        <small> / {i18n("month")}</small>
                      )}
                    {property.saleType === "rent" &&
                      property.rental_isShortTerm && (
                        <small> / {i18n("day")}</small>
                      )}
                  </>
                ) : (
                  i18n("Price on request")
                )}
              </span>
              {parseFloat(price) > 0 && parseFloat(property.size) > 0 && (
                <span className="price-per-m">{pricePerM2} €/㎡</span>
              )}
            </div>
            {/* {priceReductionPercentage > 0 && (
              <div className="property-panel-card_contents_section1_prices_was_price">
                <span className="was-price">{i18n("was")} {previousPrice} €</span>
                <div className="was-price-diff">
                  <img src={priceChangeNegativeIcon} alt="" />
                  <span>{priceReductionPercentage}%</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="property-panel-card-divider"></div>
        {renderFeatures()}
        {!(property.isPrivateBankProperty && property.size == 0) &&
        !property.rental_isShortTerm ? (
          <div className="property-panel-card-size">{renderPropertySize()}</div>
        ) : property.rental_isShortTerm ? (
          <div className="property-panel-card-size" style={{ fontSize: 11 }}>
            {i18n("Short term rental")}
          </div>
        ) : (
          <div style={{ height: 15 }}></div>
        )}
        <div className="property-panel-card-divider"></div>
        <div className="property-panel-card_footer">
          <div
            className="property-panel-card_footer_poligon-dot-content"
            data-tooltip-id={`polygon-selection-tooltip-polygon-${property.id}`}
            data-tooltip-content={
              polygon && polygon.metadata
                ? polygon.metadata.name
                : i18n("Custom area")
            }
          >
            <div className="polygon-selection-tray_row_item">
              <div
                className="polygon-selection-tray_row_item_circle"
                style={{
                  backgroundColor: polygon ? polygon.colourCode : "#fff",
                }}
              ></div>
              <Tooltip
                className="chip-tooltip"
                id={`polygon-selection-tooltip-polygon-${property.id}`}
              />
            </div>
          </div>
          <div className="property-panel-card_footer_info">
            <div className="vertical-divider"></div>
            <span>
              {property.isPrivateBankProperty && property.privateBank_assetType
                ? property.privateBank_assetType.toUpperCase()
                : sellerType}
            </span>
          </div>
          {user.userData.permissions &&
            user.userData.permissions.mark_location && (
              <Button
                onClick={onMapPin}
                style={{ padding: 0, fontSize: 10 }}
                variant="text"
              >
                📍 Pin
              </Button>
            )}
          {renderDupeMarker()}
          {isInCollection ? (
            <img
              src={likeIconLiked}
              onClick={handleTogglePropertyInCollection}
              alt=""
            />
          ) : (
            <img
              src={likeIcon}
              onClick={handleTogglePropertyInCollection}
              alt=""
            />
          )}
          {/* <img src={moreIcon} alt="" /> */}
        </div>
      </div>
      {renderTooltips()}
    </div>
  );
};

export default connect((state) => ({
  collections: state.collections.collections,
  admin: state.admin,
  user: state.user,
  drawnPolygons: state.polygon.drawnPolygons,
  setPricingModal: require("../../actions/miscActions").setPricingModal,
}))(React.memo(PropertyCard));
