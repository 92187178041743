import { blacklistProperty } from "api/admin";
import infoIcon from "assets/core/info.svg";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import bankPropertyIcon from "../../../assets/property/bank_property.svg";
import priceChangeNegativeIcon from "../../../assets/property/price_change_negative.svg";
import {
  formatNumber,
  getPriceChangePercentage,
  getPriceDropPercentage,
  getPricePerM2,
} from "../../../utils/helpers";
import {
  getFeaturesFromProperty,
  getPropertyTitle,
  isPropertyFromDataSource,
} from "../../../utils/properties";
import PropertyFeatureChip, {
  PROPERTY_FEATURE_TYPE,
} from "../PropertyFeatureChip";

const FIXED_HEADER_ENGAGEMENT_THRESHOLD = 116;

const PropertyDetailsHeader = connect((state) => ({
  collections: state.collections.collections,
  user: state.user,
  userSubscription: state.auth.subscription.data,
}))((props) => {
  let { property, user, isDeletedProperty, userSubscription } = props;
  const title = getPropertyTitle(property);
  const price = formatNumber(property.price);
  const pricePerM2 = getPricePerM2(property);
  let priceReductionPercentage = getPriceChangePercentage(property);

  if (priceReductionPercentage > 0) {
    priceReductionPercentage = 0;
  }

  let previousPrice = null;
  if (property.priceHistory && property.priceHistory.length > 0) {
    previousPrice = formatNumber(
      property.priceHistory[property.priceHistory.length - 1].price,
    );
  }

  const features = getFeaturesFromProperty(property);

  // if bank property
  let bedroomAndBathroomFeatures = null;
  let otherFeaturesLen = null;
  if (!property.isPrivateBankProperty) {
    bedroomAndBathroomFeatures = features.slice(0, 2);
    otherFeaturesLen = Math.max(0, features.length - 2);
  }

  const isLiked = props.collections.find((c) =>
    c.properties.find((p) => p.id === property.id),
  );

  const onBlacklistProperty = async () => {
    await blacklistProperty(property.id);
    toast.message("Property blacklisted successfully");
  };

  const DesktopHeader = () => {
    return (
      <div
        className={
          "property-details_header" +
          (props.className ? ` ${props.className}` : "")
        }
      >
        <div className="property-details_header_wrapper">
          <div className="property-details_header_tile-subtitle">
            <div className="property-details_header_title">
              {/* bank property card */}
              {props.property.isPrivateBankProperty && (
                <div className="property-details_header_bank_chip_container">
                  <div className="property-details_header_bank_chip">
                    <img src={bankPropertyIcon} alt="" />
                    <span>{i18n("Bank Property")}</span>
                  </div>
                </div>
              )}
              <span>{title}</span>
              {isDeletedProperty && (
                <div className="property-details_header_title_unlisted">
                  <img src={infoIcon} />
                  <span>
                    <strong>{i18n("Property unlisted")}</strong>{" "}
                    {moment.unix(property.archiveTime).format("DD/MM/YY")}
                  </span>
                </div>
              )}

              <div className="flex-1"></div>
              <div className="property-details_header_title_action-buttons">
                {user.userData.permissions &&
                  user.userData.permissions.blacklist_properties && (
                    <Button
                      className="property-card_blacklist"
                      onClick={onBlacklistProperty}
                    >
                      Blacklist
                    </Button>
                  )}
              </div>
            </div>
            <div className="property-details_header_subheader">
              <div className="property-details_header_subheader_price">
                <span className="price">
                  {parseFloat(price) > 0
                    ? `${price} €`
                    : i18n("Price on request")}
                </span>
                {property.saleType !== "rent" &&
                  parseFloat(price) > 0 &&
                  parseFloat(property.size) > 0 && (
                    <span className="price-per-m2">({pricePerM2} €/㎡)</span>
                  )}
                {property.saleType === "rent" && (
                  <span className="price-per-m2">
                    {i18n(property.rental_isShortTerm ? " / day" : " / month")}
                  </span>
                )}
                {priceReductionPercentage < 0 &&
                  !isPropertyFromDataSource(property, "airbnb") && (
                    <div className="property-details_header_subheader_was_price">
                      <span className="was-price">
                        {i18n("was")} {previousPrice} €
                      </span>
                      <div className="was-price-diff">
                        <img
                          src={priceChangeNegativeIcon}
                          alt="Price reduction"
                        />
                        <span>{Math.abs(priceReductionPercentage)}%</span>
                      </div>
                    </div>
                  )}

                <div className="flex-1"></div>
                <div className="property-details_header_title_action-buttons">
                  {user.userData.permissions &&
                    user.userData.permissions.blacklist_properties && (
                      <Button
                        className="property-card_blacklist"
                        onClick={onBlacklistProperty}
                      >
                        Blacklist
                      </Button>
                    )}
                </div>
              </div>
              <div className="vertical-divider"></div>
              <div className="property-details_header_subheader_features">
                {bedroomAndBathroomFeatures ? (
                  <div className="property-details_header_subheader_features">
                    {bedroomAndBathroomFeatures.map((feature, index) => (
                      <PropertyFeatureChip key={index} feature={feature} />
                    ))}
                    {otherFeaturesLen > 0 && (
                      <PropertyFeatureChip
                        feature={{
                          type: PROPERTY_FEATURE_TYPE.more,
                          value: `${otherFeaturesLen} ${i18n("more")}`,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  features.map((feature, index) => (
                    <PropertyFeatureChip key={index} feature={feature} />
                  ))
                )}
              </div>
              {!(property.size == 0) && !property.rental_isShortTerm && (
                <>
                  <div className="vertical-divider"></div>
                  <div className="property-details_header_subheader_area">
                    <span>
                      {formatNumber(property.size)}
                      {i18n("m² built")}
                    </span>
                  </div>
                </>
              )}
              {property.rental_isShortTerm && (
                <>
                  <div className="vertical-divider"></div>
                  <div className="property-details_header_subheader_area">
                    <span>{i18n("Short term rental")}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="property-details_header_like-report-content">
            <Button
              className="like-btn"
              onClick={isLiked ? props.onUnlikeProperty : props.onLikeProperty}
              variant="secondary"
              icon={isLiked ? "liked" : "like"}
            >
              <span>{isLiked ? i18n("Saved") : i18n("Save")}</span>
            </Button>
            {userSubscription && userSubscription.status === "active" && (
              <Button
                className="report-btn"
                onClick={() => {
                  props.setIsReportModalOpen(true);
                  sendAnalyticsEvent("Property Page Click", {
                    type: "report button",
                    details: property,
                  });
                }}
                variant="text"
                icon="report"
              >
                <span>{i18n("Report")}</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const MobileHeader = () => {
    return (
      <div
        className={
          "property-details_header mobile hidden" +
          (props.className ? ` ${props.className}` : "")
        }
      >
        <div className="property-details_header_wrapper">
          <div className="property-details_header_title-subtitle">
            <div className="property-details_header_title">
              <span>{title}</span>
              <div className="property-details_header_title_action-buttons"></div>
            </div>
            <div className="property-details_header_subheader">
              <div className="property-details_header_subheader_price">
                <span className="price">{price} €</span>
              </div>
            </div>
          </div>
          <div className="property-details_header_like-report-content">
            <Button
              className="like-btn"
              onClick={isLiked ? props.onUnlikeProperty : props.onLikeProperty}
              variant="secondary"
              icon={isLiked ? "liked" : "like"}
            />
            {userSubscription && userSubscription.status === "active" && (
              <Button
                className="report-btn"
                onClick={() => props.setIsReportModalOpen(true)}
                variant="text"
                icon="report"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <DesktopHeader />
      <MobileHeader />
    </>
  );
});

const PropertyDetailsHeaderWrapper = (props) => {
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [props.property.id]);

  return (
    <>
      <PropertyDetailsHeader {...props} />
      {scrollPos > FIXED_HEADER_ENGAGEMENT_THRESHOLD && (
        <PropertyDetailsHeader
          className="fixed-header"
          property={props.property}
          onLikeProperty={props.onLikeProperty}
          onUnlikeProperty={props.onUnlikeProperty}
          isReportModalOpen={props.isReportModalOpen}
          setIsReportModalOpen={props.setIsReportModalOpen}
        />
      )}
    </>
  );
};

export default PropertyDetailsHeaderWrapper;
