import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import PUBLIC_MEDIA_BASE_URL from "utils/file";
import copy from "../../assets/core/copy.svg";
import email from "../../assets/core/email.svg";
import telegram from "../../assets/core/telegram.svg";
import whatsapp from "../../assets/core/whatsapp.svg";

const SharePropertyModal = ({ isOpen, closeModal }) => {
  const [copiedLink, setCopiedLink] = useState(false);

  const { photos, title, propertyId, bedrooms, bathrooms, price, isPrivate } =
    useSelector((state) => state.myProperties);

  const propertyUrl = `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}property/${propertyId}`;
  const shareMessage = `${title}\n${propertyUrl}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(propertyUrl);
      setCopiedLink(true);
      toast.success(i18n("Link copied to clipboard"));
      setTimeout(() => setCopiedLink(false), 2000);
    } catch (err) {
      toast.error(i18n("Failed to copy link"));
    }
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareMessage)}`;
    window.location.href = emailUrl;
  };

  const handleTelegramShare = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(propertyUrl)}&text=${encodeURIComponent(title)}`;
    window.open(telegramUrl, "_blank");
  };

  const ShareButton = ({ onClick, label, icon, className = "" }) => (
    <div
      onClick={onClick}
      className={`flex h-[80px] w-full cursor-pointer items-center gap-[12px] border-b-[1px] border-[#E1E4EB] text-[#222222] hover:bg-gray-50 ${className}`}
    >
      {icon && <img src={icon} alt={label} />}
      <p className="font-semibold">{label}</p>
    </div>
  );

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Share your property")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-[24px] p-[24px] pt-[0px]">
          <div
            className={`flex h-[110px] w-full rounded-[8px] border-[1px] ${
              !isPrivate ? "border-black bg-[#2222221A]" : "border-[#E1E4EB]"
            }`}
          >
            <div
              className={`flex h-[110px] w-full rounded-[8px] border-[#E1E4EB] ${
                !isPrivate ? "bg-[#2222221A]" : ""
              }`}
            >
              <img
                className="h-[110px] w-[160px] rounded-lg object-cover"
                src={photos[0]?.url}
                alt={title}
              />
              <div className="flex flex-col justify-between px-[24px] py-[16px]">
                <p
                  className={`text-[16px] font-semibold ${!isPrivate ? "text-black" : ""}`}
                >
                  {title}
                </p>
                <p
                  className={`text-[16px] font-semibold ${!isPrivate ? "text-black" : ""}`}
                >
                  {price}
                </p>
                <p
                  className={`text-[14px] ${!isPrivate ? "text-black" : "text-[#717171]"}`}
                >
                  {`${bedrooms} ${i18n("bedrooms")}, ${bathrooms} ${i18n("bathrooms")}`}
                </p>
              </div>
            </div>
          </div>

          {!isPrivate && (
            <div
              className={
                "flex h-auto w-full rounded-[8px] border-[1px] border-black bg-[#2222221A] p-[16px]"
              }
            >
              <p className="text-[16px] text-black">
                {i18n("This property is public and can be shared with anyone.")}
              </p>
            </div>
          )}

          {isPrivate && (
            <div
              className={
                "flex h-auto w-full rounded-[8px] border-[1px] border-black bg-[#2222221A] p-[16px]"
              }
            >
              <p className="text-[16px] text-black">
                {i18n(
                  "This property is private. Only people with the link can access it.",
                )}
              </p>
            </div>
          )}

          <p className="text-[#717171]">
            {i18n("Select how you'd like to share this property.")}
          </p>

          <div className="w-full">
            <ShareButton
              onClick={handleCopyLink}
              label={
                copiedLink
                  ? i18n("Link Copied!")
                  : i18n("Copy Link to Property")
              }
              icon={copy}
            />
            <ShareButton
              onClick={handleWhatsAppShare}
              label={i18n("WhatsApp")}
              icon={whatsapp}
            />
            <ShareButton
              onClick={handleEmailShare}
              label={i18n("Email")}
              icon={email}
            />
            <ShareButton
              onClick={handleTelegramShare}
              label={i18n("Telegram")}
              icon={telegram}
              className="border-b-0"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharePropertyModal;
