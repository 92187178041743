import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import Button from "../ui/Button/Button";
import ListingAgent from "./ListingAgent";

const ListingAgentSettingsModal = ({ isOpen, closeModal }) => {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Visibility status settings")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <ListingAgent styleClass="settings-modal" />
        </div>
        <DialogFooter>
          <div className="flex w-full justify-between">
            <Button onClick={closeModal}>
              <div>{i18n("Cancel")}</div>
            </Button>
            <Button onClick={closeModal}>
              <div>{i18n("Save")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ListingAgentSettingsModal;
