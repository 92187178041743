import { setHideMapTools } from "actions/mapActions";
import {
  setCatastroProperty,
  setIsApproximateLocation,
  setMunicipality,
} from "actions/myPropertiesActions";
import { getCatastralByRefDirect } from "api/cma";
import RadioGroup from "components/ui/RadioGroup/RadioGroup";
import { i18n } from "i18n/localisation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/helpers";
import { catastroToCmaProperty } from "utils/map";
import { setCanDrawGeographicPolygons } from "../../actions/mapActions";
import rightChevron from "../../assets/core/right_chevron.svg";
import PropertyMap from "../map/PropertyMap";
import { LOCATION_PREFERENCE_ITEMS } from "./uploadPropertyPageConfig";

const CatastroLocation = ({ variant = "modal" }) => {
  const dispatch = useDispatch();
  const catastroProperty = useSelector(
    (state) => state.myProperties.catastroProperty,
  );
  const selectedPlot = useSelector((state) => state.plots.selectedPlot);

  dispatch(setHideMapTools(true));
  dispatch(setCanDrawGeographicPolygons(false));

  useEffect(() => {
    if (selectedPlot) {
      const findCatastralByRef = async (ref) => {
        const promise = getCatastralByRefDirect(ref);
        promise.then((data) => {
          const property = catastroToCmaProperty(
            data,
            selectedPlot.refcat,
            selectedPlot.center_y,
            selectedPlot.center_x,
            selectedPlot,
          );

          dispatch(setCatastroProperty(property));
          dispatch(setMunicipality(property.municipality));
        });
      };

      findCatastralByRef(selectedPlot.refcat);
    }
  }, [selectedPlot]);

  const getSize = () => {
    if (isNaN(catastroProperty.size) && isNaN(catastroProperty.plotSize)) {
      return;
    }

    if (catastroProperty.buildingType === "land") {
      return `${isNaN(catastroProperty.plotSize) ? formatNumber(catastroProperty.size) : formatNumber(catastroProperty.plotSize)}  ㎡`;
    }

    return `${isNaN(catastroProperty.size) ? formatNumber(catastroProperty.plotSize) : formatNumber(catastroProperty.size)}  ㎡`;
  };

  return (
    <div>
      <PropertyMap page="myProperties" hideSearchBar />
      {catastroProperty && (
        <div
          className={`${variant === "modal" ? "max-h-[calc(100svh-550px)]" : "h-auto"} w-screen overflow-scroll px-[24px] sm:w-full`}
        >
          <div className="py-[24px]">
            <div className="mb-[8px] flex w-full justify-between text-[16px] font-[500] text-[#222222]">
              <p>{catastroProperty.ref}</p>
              {catastroProperty.url && (
                <a
                  target={catastroProperty.ref}
                  className="flex"
                  href={catastroProperty.url}
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>Catastro</span>
                  <img src={rightChevron} alt={"chevron icon"} />
                </a>
              )}
            </div>
            <p className="w-full sm:w-[400px]">{catastroProperty.address}</p>
            <p className="mb-[12px]">{catastroProperty.zip_code}</p>
            <div className="flex gap-[8px]">
              <span>{catastroProperty.type}</span>
              <span>{getSize()}</span>
            </div>
          </div>
          <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
          <div className="flex flex-col gap-[8px] py-[24px]">
            <p>{i18n("Location preferences")}</p>
            <p className="text-base font-normal text-[#717171]">
              {i18n(
                "Showing exact location increases sale rate by an average of X amount",
              )}
            </p>
            <RadioGroup
              direction={window.innerWidth < 1100 ? "column" : "row"}
              items={LOCATION_PREFERENCE_ITEMS.map((item) => item.label)}
              onSelect={(selected) =>
                dispatch(
                  setIsApproximateLocation(
                    LOCATION_PREFERENCE_ITEMS.find(
                      (item) => item.label === selected,
                    ).value,
                  ),
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CatastroLocation;
