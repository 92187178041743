import { setDescription } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import plusIcon from "assets/core/plus.svg";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../ui/Button/Button";

const CONTENT = {
  EMPTY_DESCRIPTION: "emptyDescription",
  DESCRIPTION_TEXT: "descriptionText",
  INPUT_DESCRIPTION: "inputDescription",
};

const EmptyDescription = ({ setContent }) => {
  return (
    <div className="media-empty-tile h-[267px]">
      <div className="flex max-w-[354px] flex-col items-center justify-center gap-[16px]">
        <Button
          variant="secondary"
          className="flex h-[44px] max-w-[253px] justify-around gap-[8px]"
          onClick={() => setContent("inputDescription")}
        >
          <img src={plusIcon} alt="add media" />
          <span className="text-[16px] font-medium">
            {i18n("Add property description")}
          </span>
        </Button>
        <p className="text-center text-[14px] text-[#717171]">
          {i18n(
            "Add a description including details on features, condition, amenities, surroundings etc.",
          )}
        </p>
      </div>
    </div>
  );
};

const InputDescription = ({ value, setValue }) => {
  return (
    <div>
      <label className="text-[14px] text-[#717171]">
        {i18n(
          "Add a description including details on features, condition, amenities, surroundings etc.",
        )}
      </label>
      <textarea
        id="description"
        className="mt-[16px] min-h-[200px] w-full rounded-lg border-[1px] border-[#BABABA] p-[10px]"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const DescriptionText = ({ description }) => {
  return (
    <div className="h-auto min-h-[200] w-full">
      <p className="text-[16px] text-[#717171]">{description}</p>
    </div>
  );
};

const Description = () => {
  const dispatch = useDispatch();
  const description = useSelector((state) => state.myProperties.description);

  const { propertyId } = useParams();
  const [textInInputDescription, setTextInInputDescription] = useState("");
  const [content, setContent] = useState(CONTENT.EMPTY_DESCRIPTION);

  useEffect(() => {
    if (description) {
      setTextInInputDescription(description);
      setContent(CONTENT.DESCRIPTION_TEXT);
    }
  }, [description]);

  const handleSaveDescription = async () => {
    dispatch(setDescription(textInInputDescription));
    try {
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: { description: textInInputDescription },
      });
    } catch (e) {
      console.log(e);
    }

    setContent(CONTENT.DESCRIPTION_TEXT);
  };

  const handleCancel = () => {
    setTextInInputDescription(description);
    setContent(CONTENT.DESCRIPTION_TEXT);
  };

  const handleEdit = () => {
    setContent(CONTENT.INPUT_DESCRIPTION);
  };

  return (
    <div className="description_container">
      <div className="description_header">
        <p>{i18n("Description")}</p>
      </div>
      <div className="description_add-description-container">
        {content === CONTENT.EMPTY_DESCRIPTION && (
          <EmptyDescription setContent={setContent} />
        )}
        {content === CONTENT.INPUT_DESCRIPTION && (
          <InputDescription
            value={textInInputDescription}
            setValue={setTextInInputDescription}
          />
        )}
        {content === CONTENT.DESCRIPTION_TEXT && (
          <DescriptionText description={textInInputDescription} />
        )}
      </div>
      <div className="description_footer">
        {content === "inputDescription" && (
          <div className="description_footer_cta-container">
            <Button variant="secondary" onClick={handleCancel}>
              <span>{i18n("Cancel")}</span>
            </Button>
            <Button
              onClick={handleSaveDescription}
              disabled={textInInputDescription?.trim().length === 0}
            >
              <span>{i18n("Save")}</span>
            </Button>
          </div>
        )}
        {content === CONTENT.DESCRIPTION_TEXT && (
          <div className="description_footer_cta-container">
            <div className="h-[40px] rounded-full bg-[#12B76A] px-[16px] py-[10px]">
              <p className="text-[14px] text-[#FFFFFF]">{i18n("Complete")}</p>
            </div>
            <Button onClick={handleEdit}>
              <span>{i18n("Edit")}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Description;
